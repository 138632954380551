.slide {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}


.landing-slides-heading {
  font-family: $spock;
  font-weight: $bold;
  text-transform: uppercase;
  font-size: 3.2rem;
  line-height: 1.1;
  color: white;
  margin: 0;
  & .flex-break {
    width: 100%;
    height: 0;
  }

  @include for-desktop-down {
    font-size: 2.4rem;
  }

  @include for-tablet-landscape-down {
    font-size: 2.2rem;
  }

  @include for-tablet-portrait-down {
    font-size: 1.6rem;
  }
}

