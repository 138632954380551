.user-profile-tab {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  font-weight: 500;
  &:focus {
    outline: none;
  }

  @include for-tablet-portrait-down {
    font-size: 0.7rem;
  }
}

.tab-positioning {
  position: absolute;
  top: 0;
  left: 0;
}

.tab-margin {
  margin: 0 2rem 0 2rem;
  @include for-tablet-landscape-down {
    margin: 0 1rem 0 1rem;
  }
}
