.landing-7-margin {
  margin-left: 60px;
  @include for-big-desktop-down {
    margin-left: 40px;
  }
  @include for-desktop-down {
    margin-left: 0;
  }
}

.landing-7-content {
  font-family: $noto;
  width: 792px;

  @include for-desktop-down {
    width: 480px;
  }
  @include for-tablet-landscape-down {
    width: 80%;
    margin-left: 0;
  }

  @include for-tablet-portrait-down {
    width: 100%;
    margin-left: 0;
  }

  h2 {
    font-family: $spock;
    font-size: 3.2rem;
    text-transform: uppercase;

    @include for-desktop-down {
      font-size: 2.8rem;
    }
    @include for-tablet-landscape-down {
      font-size: 2.2rem;
    }

    @include for-tablet-portrait-down {
      font-size: 1.6rem;
    }
  }
  h3 {
    font-size: 1.6rem;
    font-style: italic;
    font-weight: 500;
    text-transform: uppercase;

    @include for-desktop-down {
      font-size: 1.4rem;
    }
    @include for-tablet-landscape-down {
      font-size: 1rem;
    }
    @include for-tablet-portrait-down {
      font-size: 0.7rem;
    }
  }
  p {
    font-size: 1.3rem;
    @include for-desktop-down {
      font-size: 1.1rem;
    }
    @include for-tablet-landscape-down {
      font-size: 0.9rem;
    }
    @include for-tablet-portrait-down {
      font-size: 0.7rem;
    }
  }

  @include for-big-desktop-down {
    .landing-7-text {
      background-color: rgba(255, 255, 255, 0.75);
    }
  }
}
