.cart-wrapper{
  @include for-extra-extra-large-down {
    max-width: 97%;
    margin: 0 auto;
  }

  @include for-tablet-landscape-down {
    max-width: 100%;
  }
}

.cart-item {
  height: 80px;
  padding-right: 2rem;

  img {
    height: 100%;
    width: 80px;
    object-fit: cover;

    @include for-desktop-down {
      width: auto;
    }

    @include for-small-phone-down {
      width: 30%;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  @include for-desktop-down {
    height: 120px;
  }

  @include for-tablet-landscape-down {
    padding-right: 1rem;

    .remove-left-padding-mobile {
      .normal-text-p.ml-10 {
        margin-left: 0 !important;
      }
    }
  }

  @include for-tablet-portrait-down {
    height: 170px;
    padding-right: 0;
  }

  @include for-small-phone-down {
    height: 120px;
  }
}

.cart-items-scroll-area {
  overflow-x: hidden;
  height: 360px;
}

.checkout-stacking {
  align-items: center;

  @include for-tablet-landscape-down {
    flex-direction: column-reverse;
    align-items: initial;
  }
}

.no-word-break {
  white-space: nowrap;
}

.cart-item-price-width {
  @include for-desktop-down {
    .row {
      margin: 0 auto;
    }
  }
}

.cart-item-prices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  @include for-desktop-down {
    justify-content: flex-end;
    p {
      width: 118px;
      text-align: center;
    }
  }

  @include for-small-phone-down {
    p {
      text-align: left;
    }
  }
}

.cart-item-incrementer {
  display: flex;
  justify-content: flex-end;
}

.mobile-column-height {
  height: 100%;
  @include for-tablet-portrait-down {
    height: auto;
  }
}

.empty-cart-notice {
  display:block;
  text-align: center;
  text-transform: uppercase;
  font-size: 2.8rem;
  margin: 8rem 0;
  font-weight: bold;

  @include for-tablet-portrait-down {
     font-size:2rem;
  }
}



// .cart-items-container {
//   position: relative;
//   &:after {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     height: 10%;
//     width: 100%;
//     z-index: 1;
//     content: '';
//     pointer-events: none;
//     background-image: linear-gradient( transparent 1%, $lightgreen 60%);
//   }
// }
