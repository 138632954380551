@include for-desktop-up {
  .section-numbers-clip-container {
    position: relative;
    min-height: 720px;
    min-width: 100vw;
    > div:first-child {
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      clip: rect(0, auto, auto, 0);
      pointer-events: none;
    }
  }
}


.section-numbers {
  position: fixed;
  top: 0;
  left: 10px;
  z-index: 4;
  height: 100vh;
  width: 100px;
  overflow-y: hidden;
  font-family: $spock;
  pointer-events: none;

  @include for-desktop-down {
    display: none;
  }

  & .numbers {
    height: 100vh;
    width: 30px;
    top: 0;
  }

  & .section-numbers-line {
    width: 1px;
  }

  & .white-numbers a {
    pointer-events: auto;
    color: white;
  }

  & .black-numbers a {
    pointer-events: auto;
    color: black;
  }

  & p {
    margin: 10px 0;
  }

}

.section-heading {
  font-family: $spock;
  font-size: 4rem;
  font-weight: $black;
  text-transform: uppercase;
  line-height: 1.1;
  white-space: pre-wrap;
  margin-top: 0;
  margin-bottom: 0;


  @include for-tablet-portrait-down {
    margin-top: 20px !important;
  }

  @include for-extra-extra-large-down {
    font-size: 3.6rem;
  }

  @include for-big-desktop-down {
    font-size: 3rem;
  }

  @include for-tablet-landscape-down {
    font-size: 2.2rem;
    margin-bottom: 10px;
  }

  @include for-tablet-portrait-down {
    font-size: 2rem;
    text-align: center !important;
  }
}

.section-heading-small {
  font-size: 4rem;
  @include for-big-desktop-down {
    font-size: 3.4rem;
  }
  @include for-desktop-down {
    font-size: 2.8rem;
  }
  @include for-tablet-landscape-down {
    font-size: 2.2rem;
    margin-bottom: 10px;
  }
  @include for-tablet-portrait-down {
    font-size: 1.9rem;
  }
}

.bg-contact-us-section-4 {
  .section-heading-small {
    font-size: 3.6rem;
    @include for-big-desktop-down {
      font-size: 3rem;
    }
    @include for-desktop-down {
      font-size: 2.6rem;
    }
  }
}

.section-heading-smaller {
  font-size: 3.3rem;
  @include for-big-desktop-down {
    font-size: 2.7rem;
  }
  @include for-desktop-down {
    font-size: 2.3rem;
  }
  @include for-tablet-landscape-down {
    font-size: 1.9rem;
    margin-bottom: 10px;
  }
  @include for-tablet-portrait-down {
    font-size: 1.6rem;
  }
}

.section-heading-smallest {
  font-size: 1.8rem;
  @include for-big-desktop-down {
    font-size: 2rem;
  }
  @include for-desktop-down {
    font-size: 1.8rem;
  }
  @include for-tablet-landscape-down {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  @include for-tablet-portrait-down {
    font-size: 1rem;
  }
}

.text-styling {
  font-family: $noto;
  font-size: 1.3rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.2;
  white-space: pre-wrap;
  @include for-big-desktop-down {
    font-size: 1.15rem;
  }
  @include for-desktop-down {
    font-size: 1.05rem;
  }
}

@media (max-width: 386px) { 
  .location-profile-section-2{
    .text-styling {
      font-size: 1.02rem;
    }
  }
}

@include for-tablet-landscape-down {
  .location-profile-section-2{
    .dynamic-viewport-height.justify-end{
      justify-content: center !important;
      padding-bottom: unset;
    }
  }
}


.text-block-attribution {
  text-align: right;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.1rem;
  @include for-big-desktop-down {
    font-size: 0.9rem;
  }
  @include for-tablet-landscape-down {
    font-size: 0.7rem;
  }
}


.text-block {
  margin-bottom: 40px;
  padding-left: 18px;
}

.border-white-slim {
  border: 4px solid white !important;
  @include for-tablet-portrait-down {
    border: 2px solid white !important;
  }
}

.border-left-lightgreen {
  border-left: 8px solid $lightgreen;
  @include for-tablet-portrait-down {
    border-left: 4px solid $lightgreen;
  }
}

.border-left-white {
  border-left: 8px solid white;
  @include for-tablet-portrait-down {
    border-left: 4px solid white;
  }
}

.border-left-white-slim {
  border-left: 4px solid white;
  @include for-tablet-portrait-down {
    border-left: 2px solid white;
  }
}


.active-slide-indicator {
  display: flex;
  width: 100%;
  justify-content: center;
}

.indicator-within {
  z-index: 1;
  position: absolute;
  bottom: 20px;
}

.indicator-below {
  position: relative;
  margin-top: 20px;  
}

.custom-dot {
  position: relative;
  margin-top: 20px;
} 

.custom-dot-list {
  bottom: -40px;

  @include for-desktop-down {
    bottom: -20px;
  }

}

.dot {
  margin: 0 5px 0 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.2s;
  cursor: pointer;

  @include for-tablet-landscape-down {
    width: 15px;
    height: 15px;
    margin: 0 8px 0 8px;
  }
}

.fact-counter {
  width: 200px;
  margin-bottom: 30px;
  font-family: $spock;
  text-transform: uppercase;

  @include for-small-phone-down {
    width: unset;
  }

  & > h1 {
    font-weight: 800;
    font-size: 70px;
    line-height: 1;
    margin-bottom: 0;

    @include for-tablet-landscape-down {
      font-size: 2.5rem;
    }

    @include for-tablet-portrait-down {
      font-size: 1.8rem;
    }
  }

  & > h2{
    font-size: 4.5rem;
    margin-bottom: 0px;

    @include for-big-desktop-down {
      font-size: 3.5rem;
    }

    @include for-desktop-down {
      font-size: 3.5rem;
    }

    @include for-tablet-landscape-down {
      font-size: 2.7rem;
    }
  }

  & > h3 {
    font-size: 20px;
    text-align: center;
    width: 140px;
    margin: 0;

    @include for-tablet-landscape-down {
      width: 100px;
      font-size: 0.9rem;
      overflow-wrap: normal;
    }

    @include for-tablet-portrait-down {
      font-size: 0.7rem;
       width: 80px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.general-button {
  padding: 15px 20px 12px 20px;
  border-radius: 8px;
  border: none;

  font-family: $spock;
  font-style: italic;
  font-weight: 800;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-decoration: none;
}

.general-button-dynamic {
  @include for-tablet-landscape-down {
    padding: 12px 15px 10px 15px;
    font-size: 1.2rem;
  }
  
  @include for-small-phone-down {
    font-size: 1rem;
  }
}

.narrow-button {
  padding: 8px 17px 6px 17px;
  @include for-tablet-landscape-down {
    padding: 6px 12px 5px 12px;
  }
}

.small-button {
  padding: 6px 13px 5px 13px;
  font-size: 0.9rem;
  @include for-tablet-landscape-down {
    font-size: 0.7rem;
    padding: 4px 8px 3px 8px;
  }
}

.added-to-cart-button {
  background-color: $lightgreen !important;
  color: white !important;
}

.disabled-button {
  pointer-events: none;
  a {
    color: white;
    background-color: $lightgray;
  }
}

.animal-square-container {

  max-width: 800px;
  max-height: 800px;

  width: 42vw;
  height: 42vw;
  @include for-desktop-down {
    width: 65vw;
    height: 65vw;
  }
  @include for-tablet-landscape-down {
    width: 85vw;
    height: 85vw;
  }
}

.animal-square {
  position: relative;
  margin: 20px;
  height: fit-content;

  img {
    margin: 0;
    width: 20vw;
    height: 20vw;
    max-width: 350px;
    max-height: 350px;

    @include for-desktop-down {
      width: 30vw;
      height: 30vw;
    }
    @include for-tablet-landscape-down {
      width: 40vw;
      height: 40vw;
    }
  }
  div {
    bottom: 10px;
  }
}

.animal-square-small {
  margin: 10px;

  @include for-small-phone-down {
    div.pb-20{
      padding-bottom: 0;
    }

    .general-button-dynamic{
      font-size: 0.8rem;
    }
  }

  img {
    margin: 0;
    width: 18vw;
    height: 18vw;
    max-width: 350px;
    max-height: 350px;

    @include for-desktop-down {
      width: 24vw;
      height: 24vw;
    }
    @include for-tablet-landscape-down {
      width: 30vw;
      height: 30vw;
    }
  }

  div {
    bottom: 10px;
  }
}

.filter {
  border: 2px solid $lightgreen;
  background-color: white;
  text-transform: uppercase;
  font-family: $spock;
  font-weight: 600;
  font-size: 0.9rem;
  font-style: italic;
  transition: 0.2s;
  padding: 4px 6px;
  &:focus {
    box-shadow: 0;
    outline: none;
  }
}

.isOpen {
  background-color: $lightgreen;
  color: white !important;
}

.flipped {
  display: inline-block;
  transform: rotate(180deg);
}

.read-more-popup {
  h3 {
    @extend .section-heading-smallest;
    color: $forest;
    margin: 0;
  }

  h4 {
    @extend .section-heading-smallest;
    color: $darkgreen;
    margin: 0;
  }

  p {
    @extend .text-styling;
    color: $darkgreen;
  }

  .pop-up-container{
    > div{
      > div{
        @include for-extra-extra-large-down {
          padding: 2rem;
        }

        @include for-extra-extra-large-up {
          padding: 2rem;
        }

        @include for-tablet-landscape-down {
          padding: 0;
        }
      }
    }
  }
}


.general-button.block.general-button-dynamic.bg-darkgreen.pt-10.pb-0.pl-20.pr-20.text-white.w-max-content{

  font-size: 1.2rem;
}
#root {
  .select {
    border-radius: 8px;
    border: none;
    font-family: "Arial";
    font-weight: 500;
    font-size: 1.2rem;
    text-decoration: none;
    font-style: italic;
  }

  .css-13cymwt-control,
  .css-t3ipsp-control {
    background-color: $lightgreen;
    font-size: 1.2rem;
    height: 60px;
  }

  .css-1jqq78o-placeholder {
    color: $darkgreen;
    font-size: 1.2rem;
  }

  .css-1u9des2-indicatorSeparator {
    background-color: $darkgreen;
  }

  .css-1xc3v61-indicatorContainer svg {
    color: $darkgreen;
  }

}