.viewport-size {
  min-height: 720px;
  min-width: 100vw;
  // overflow: hidden;
}

.loader-height{
  min-height: 100vh;
}

.inner-page-viewport-size {
  min-height: 100px;
}

.viewport-height {
  // height: 100vh;
  min-height: 720px;
}

.inner-page-viewport-height {
  min-height: 100px;

  @include for-small-phone-down {
    min-height: 200px;

    h2{
      margin-bottom: 2rem;
    }
  }
}

.lazyload-placeholder-height-small {
  height: 200px;
}

.anchor-smooth-scroll {
  scroll-behavior: smooth;
}

.bg-white{
  background-color: white;
}

.bg-lightgray{
  background-color: $lightgray;
}

.bg-black{
  background-color: black;
}

.bg-forest{
  background-color: $forest;
}

.bg-lightgreen{
  background-color: $lightgreen;
}

.bg-darkgreen{
  background-color: $darkgreen;
}

.bg-darkred{
  background-color: $darkred;
}

.bg-transparent{
  background: transparent;
}

.bg-forest-transparent{
  background-color: rgba(41, 68, 36, 0.8);
}

.bg-lightgreen-transparent{
  background-color: rgba(140, 200, 113, 0.8);
}

.bg-darkgreen-transparent{
  background-color: rgba(50, 77, 0, 0.8);
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.87);
}

.bg-black-transparent {
  background-color: rgba(0, 0, 0, 0.82);
}


.text-white {
  color: white;
}

.text-lightgray {
  color: $lightgray !important;
}

.text-darkgray {
  color: $darkgray;
}

.text-forest {
  color: $forest !important;
  border-color: $forest !important;
}

.text-lightgreen{
  color: $lightgreen;
  border-color: $lightgreen;
}

.text-darkgreen{
  color: $darkgreen;
  border-color: $darkgreen;
}

.text-red{
  color: $red;
  border-color: $red;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-column {
  grid-auto-flow: column;
  grid-template-rows: 1fr 1fr;
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

@include for-desktop-down {
  .align-md-start {
    align-items: flex-start;
  }
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-stretch {
  justify-content: stretch;
}
.justify-start {
  justify-content: flex-start;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.w-max-content {
  width: max-content;
}

.w-min-content {
  width: min-content;
}

.w-fit-content {
  width: fit-content;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.max-w-450px {
  max-width: 450px;
}

.max-w-500px {
  max-width: 500px;
}

.max-w-600px {
  max-width: 580px;
}

.max-w-800px {
  max-width: 800px;
}

.max-w-1500px {
  max-width: 1500px;
}

@include for-desktop-down {
  .md-max-w-350px {
    max-width: 350px !important;
  }
}

@include for-tablet-landscape-down {
  .sm-max-w-240px {
      max-width: 240px !important;
    }
}

.h-40 {
  height: 40%;
}

.h-20 {
  height: 20%;
}

.h-50 {
  height: 50%;
}

.h-80 {
  height: 80%;
}

.h-90 {
  height: 90%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.h-max-content {
  height: max-content;
}

.h-min-content {
  height: min-content;
}


.top-0-left-0 {
  top: 0;
  left: 0;
}

.bottom {
  bottom: 20px;
}

.radius-8 {
  border-radius: 8px;
}

.rounded {
  border-radius: 17px;
}

.circle {
  border-radius: 50%;
}

.no-border{
  border: none;
}

.image-fit {
  object-fit: cover;
}

.box-shadow {
  box-shadow: 2px 3px 4px rgba(0,0,0,0.2);
}

.box-shadow-big {
  box-shadow: 6px 7px 7px rgba(0,0,0,0.3);
}

.absolute-center-x {
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.scale-0_9 {
  transform: scale(0.9);
}

.visible-desktop-up {
  @include for-desktop-down {
    display: none;
   
  }
}

.visible-desktop-down {
  display: none;
  @include for-desktop-down {
    display: block;
  }
}

.visible-landscape-up {
  @include for-tablet-landscape-down {
    display: none;
  }
}

.visible-portrait-up {
  @include for-tablet-portrait-down {
    display: none;
  }
}

.visible-small {
  display: none;
  @include for-tablet-landscape-down {
    display: inherit;
  }
}

.visible-phone {
  display: none;
  @include for-tablet-portrait-down {
    display: inherit;
  }
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.visibility-hidden {
  visibility: hidden;
}

.no-bullets {
  list-style-type: none;
}

.content-box {
  box-sizing: content-box;
}

.border-box {
  box-sizing: border-box;
}

.cursor-help {
  cursor: help;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.policy-page {
  ol li {
    p {
      font-weight: bold;
      font-size:1.2em;
    }

    ol li p{
      font-weight: normal;
      font-size:1em;
    }
  }
  
}

.name-tag {
  background-color: #8cc871;
  border-radius: 4px;
  color: #000;
  font-size: 12px;
  padding: 10px 15px;
  position: absolute;
  font-weight: bold;
  white-space: nowrap;
  transform: translate(-51%, -188%);
}

.name-tag::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #8cc871;
}

.pin {
  position: absolute;
  border-radius: 50% 50% 50% 0;
  border: 4px solid #294424;
  width: 20px;
  height: 20px;
  transform: translate(-48%, -115%) rotate(-45deg);
}

.pin::after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  background-color: #294424;
}

.min-height-zero{

  min-height:0;
}
.max-width-60{

  max-width: 59%;
}
.width-auto{

  width:auto;
}

.background-semi-transparent-red {
    background-color: rgba(157, 11, 14, 0.8);
    padding: 1rem;
}

.hidden {
  display:none !important;
}

.is-error {
  color: red;
}

iframe .TermsText {
  display:none;
}