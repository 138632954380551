
.mt-vh-20 {
  margin-top: 20vh;
}

.mt-vh-30 {
  margin-top: 30vh;
  @include for-bigger-than-preset-page-height {
    margin-top: 200px;
  }
}

.mt-vh-40 {
  margin-top: 40vh;
  @include for-bigger-than-preset-page-height {
    margin-top: 320px;
  }
}

.mx-auto {
  margin: 0 auto;
}

.ml-auto {
  margin-left: auto;
}

.m-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-100 {
  margin-bottom: 100px;
}


.mt-0 {
  margin-top: 0px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.ml-0 {
  margin-left: 0px;
}

@include for-desktop-down {
  .md-ml-0 {
    margin-left: 0px !important;
  }
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}
.ml-40 {
  margin-left: 40px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-100 {
  margin-left: 100px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-100 {
  margin-right: 100px;
}

@include for-desktop-up {
  .lg-mt-80 {
    margin-top:80px;
  }
  .lg-mb-60 {
    margin-bottom: 60px;
  }

  .lg-ml-40 {
    margin-left:40px;
  }
  
  .lg-ml-60 {
    margin-left: 60px;
  }
  
  .lg-ml-80 {
    margin-left: 80px;
  }
}


.p-0 {
  padding: 0;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-100 {
  padding-bottom: 100px;
}


.pt-0 {
  padding-top: 0px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-100 {
  padding-top: 100px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-100 {
  padding-left: 100px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-100 {
  padding-right: 100px;
}

.page-padding {
  padding-left: 60px;
  padding-right: 60px;
  @include for-desktop-down {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include for-tablet-portrait-down {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.bigger-page-padding {
  padding-left: 4rem;
  padding-right: 4rem;
  @include for-desktop-down {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @include for-tablet-landscape-down {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include for-tablet-portrait-down {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.column-2 {
  column-count: 2;
  column-gap: 3rem;
  @include for-big-desktop-down {
    column-gap: 2rem;
  }
  @include for-desktop-down {
    column-gap: 1.2rem;
  }
  @include for-tablet-portrait-down {
    column-gap: 0.8rem;
  }
}

.after-column-break {
  -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
                 break-inside: avoid;
}
