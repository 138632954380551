.quantity-select {

  //Remove arrows from input
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @include for-small-phone-down {
    margin-bottom: 10%;
  }

  input[type="number"] {
    margin: 0 20px;
    border: 0;
    -moz-appearance: textfield;
    background: transparent;
    color: white;
    font-family: $spock;
    font-size: 1.3rem;
    font-weight: 700;
    width: 4rem;
    text-align: center;

    @include for-extra-extra-large-down {
      margin: 0;
    }

    @include for-big-desktop-down {
      margin: 0;
    }
  }

  &.over-white {
    input[type="number"] {
      color:$darkgreen;
    }
  }
}

.value-increment {
  width: 26px;
  height: 26px;
  border-radius: 8px;
  border: 0;
  &:focus {
    outline: none;
  }
  div {
    height: 14px;
    width: 14px;
    border: 1px solid $lightgreen;
    border-width: 0 4px 4px 0;
  }
  .up {
    margin-top: 40%;
    margin-left: 10%;
    transform: rotate(-135deg) skew(6deg, 6deg);

    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */ 
      margin-top: 4px;
      margin-left: -4px;
      
      @include for-tablet-portrait-up {
        margin-top: 40%;
        margin-left: 10%;
      }
    }
  }
  .down {
    margin-bottom: 30%;
    margin-left: 10%;
    transform: rotate(45deg) skew(6deg, 6deg);

    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */ 
      margin-bottom: 4px;
      margin-left: -4px;

      @include for-tablet-portrait-up {
        margin-bottom: 30%;
        margin-left: 10%;
      }
    }
  }
}

.placeholder-for-value-increment {
  min-width: 26px;
  min-height: 26px;
  @include for-tablet-portrait-down {
    min-width: 20px;
    min-height: 20px;
  }
}

.value-increment-round {
  min-width: 26px;
  min-height: 26px;
  border-radius: 50%;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: $lightgreen;
  &:focus {
    outline: none;
  }
  p {
    font-size: 1.3rem;
  }

  @include for-tablet-portrait-down {
    min-width: 20px;
    min-height: 20px;
    p {
      font-size: 1.1rem;
    }
  }
}

.green {
  .value-increment {
    background-color: $lightgreen;
  }

  .value-increment > div {
    border-color: white;
  }

  input[type="number"] {
    color: $forest;
  }

}


.reduced-margin-mobile {
  @include for-tablet-landscape-down {
    margin: 0 8px !important;
  }
  @include for-tablet-portrait-down {
    margin: 0 !important;
  }
}
