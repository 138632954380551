.faq-form-height {
  height: 100px;

  @include for-desktop-down {
    height: 80px;
  }

  @include for-tablet-landscape-down {
    height: 70px;
  }

  @include for-tablet-portrait-down {
    height: 60px;
  }
}

.search-bar-button {
  border: none;
  top: 0;
  right: 0;
  font-size: 1.8em;

  padding-left: 20px;
  padding-right: 20px;

  &:focus {
    outline: 0;
  }

  @include for-desktop-down {
    font-size: 1.4rem;
    padding-left: 16px;
    padding-right: 16px;
  }

  @include for-tablet-landscape-down {
    font-size: 1.2rem;
    padding-left: 14px;
    padding-right: 14px;
  }

  @include for-tablet-portrait-down {
    font-size: 1rem;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.search-bar-input {
  border: none;
  font-size: 1.8rem;
  letter-spacing: 4px;

  padding-left: 7%;

  @include for-desktop-down {
    font-size: 1.4rem;
    padding-left: 5%;
  }

  @include for-tablet-landscape-down {
    font-size: 1.2rem;
    padding-left: 3%;
  }

  @include for-tablet-portrait-down {
    font-size: 0.7rem;
    padding-left: 2%;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #363a39;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #363a39;

  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #363a39;
  }
}
