.contacts-height {
  height: 500px;
   @include for-desktop-down {
    height: 350px;
  }
  @include for-tablet-landscape-down {
    height: 310px;
  }
  @include for-tablet-portrait-down {
    height: 350px;
    height: auto;
  }
}

.faq-button-width a{
  width: 450px;
  padding:2rem;
  @include for-big-desktop-down {
    width: 350px;
  }
  @include for-desktop-down {
    width: 300px;
  }
  @include for-tablet-portrait-down {
    width: 300px;
  }
}

.section-4-height {
  min-height: 600px;
}

.contact-us-section-2{

  &.viewport-size{
    @include for-big-desktop-down {
      min-height: unset;
      padding-top: 9%;
      padding-bottom: 7%;
    }
  }

  .viewport-height{
    @include for-big-desktop-down {
      min-height: unset;
    }
  }
}
