.create-your-jungle-height {
  height: 510px;
  width: 80%;
  padding: 40px 40px 20px 40px;
  @include for-desktop-down {
    height: 540px;
    max-width: 600px;
    padding: 20px;
  }
  @include for-tablet-landscape-down {
    height: 440px;
    max-width: 400px;

  }
}

.create-your-jungle-nav-height {
  min-height: 20px;
}

.create-your-jungle-subheader {
  font-family: $spock;
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 20px;
  @include for-big-desktop-down {
    font-size: 1.3rem;
  }
  @include for-desktop-down {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  @include for-tablet-landscape-down {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  @include for-tablet-portrait-down {
    font-size: 0.9rem;
  }
}

.life-event-image {
  background-color: transparent;
  border: 0;
  padding: 10px;
  transition: 0.2s;
  img {
    width: 120px;
    @include for-big-desktop-down {
      width: 80px;
    }
    @include for-desktop-down {
      width: 70px;
    }
    @include for-tablet-landscape-down {
      width: 60px;
    }
  }
  p {
    font-family: $spock;
    font-size: 0.9rem;
    font-weight: 600;
    @include for-big-desktop-down {
      font-size: 0.7rem;
    }
    @include for-desktop-down {
      font-size: 0.6rem;
    }
    @include for-tablet-landscape-down {
      font-size: 0.5rem;
    }
  }
  &:focus {
    outline: none;
  }

}

.name-and-size-card {
  height: 220px;
  width: 160px;
  transition: 0.2s;
  background-color: white;
  img {
    width: 85%;
  }
  p {
    font-family: $spock;
    font-weight: 800;
    line-height: 1.1;
  }
  div {
    bottom: -25px;
    font-weight: 600;
    text-transform: none;
  }

  @include for-desktop-down {
    height: 170px;
    width: 110px;
    p {
      font-size: 0.8rem;
    }
    .quantity-select input[type="number"] {
      margin: 0 5px;
      width: 40px;
    }
    div {
      transform: scale(0.8);
    }
  }
  @include for-tablet-landscape-down {
    height: 120px;
    width: 90px;
    margin-bottom: 30px;
    p {
      margin-top: 5px;
      font-size: 0.6rem;
      padding: 0;
      line-height: 0.4;
    }
    div {
      transform: scale(0.5);
      bottom: -22px;
    }
  }
  @include for-tablet-portrait-down {
    font-size: 0.7rem;
  }
}

.page-3-card-text {
  font-weight: 600;
  line-height: 1.1;
  font-size: 1.5rem;
  margin-bottom: 40px;
  @include for-big-desktop-down {
    font-size: 1.3rem;
  }
  @include for-desktop-down {
    font-size: 1.1rem;
  }
  @include for-tablet-landscape-down {
    font-size: 0.9rem;
  }
  @include for-tablet-portrait-down {
    font-size: 0.7rem;
  }
}

.selected-event {
  transform: scale(1.1);
  background-color: #b8e5a2;
  z-index: 1;
}

.stylized-textarea {
  resize: none;
  padding: 10px 0 0 10px;
  font-family: $spock;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.4rem;
  font-style: italic;
  border: 0;
  outline: 0;
  height: 140px;
  @include for-desktop-down {
    height: 80px;
  }
}

.create-your-jungle-page-4-logo {
  height: 200px;
}

.dynamic-centering {
  text-align: center;
  @include for-desktop-down {
    text-align: left;
  }
}

.tablet-spacer {
  height: 0px;
  @include for-desktop-down {
    height: 40px;
  }
  @include for-tablet-landscape-down {
    height: 0px;
  }
}
