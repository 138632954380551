// Currently unused, but this works - replace the npm package with this

.flip-card {
  position: relative;
  transition: 0.3s;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.flip-card-front {
  height: 100%;
  width: 100%;
  transition: 0.3s;
  backface-visibility: hidden;
}

.flip-card-back {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transform: rotateY(-180deg);
  height: 100%;
  width: 100%;
  transition: 0.3s;
  backface-visibility: hidden;
}

// separate -hovered class rather than :hover, so that we can click on a card
// To set it to permanently hovered (until clicked again)
.flip-card-hovered > .flip-card-front {
  transform: rotateY(180deg);
  transition-timing-function: ease-in-out;
}

.flip-card-hovered > .flip-card-back {
  transform: rotateY(0deg);
  transition-timing-function: ease-in-out;
}

.flip-over-y-axis {
  .flip-card-back {
    transform: rotateX(-180deg);
  }

  &.flip-card-hovered > .flip-card-front {
    transform: rotateX(180deg);
  }

  &.flip-card-hovered > .flip-card-back {
    transform: rotateX(0deg);
  }

}
