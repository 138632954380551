.purchase-history-headers {
  h2 {
    width: 20%;
    text-align: center;
    font-weight: 800;
  }
}

.purchase-history-body {
  p {
    width: 20%;
    text-align: center;
    // font-weight: ;
  }
}

.tree-database-section-2-wrap{
  @include for-small-phone-down {
    .page-padding{
      padding-top: 1rem;
    }
  }
}
