.dynamic-justify-end {
  // justify-content: flex-start;
  @include for-tablet-landscape-down {
    justify-content: flex-end;
  }
}

.settings-section {
  display: flex;
  justify-content: space-between;

  @include for-tablet-landscape-down {
    flex-direction: column;
  }

  @include for-small-phone-down {
    .tab-content {
      .flex.justify-end{
        justify-content: flex-start;
      }
    }

  }
}

.left-panel {
  width: 300px;
  @include for-tablet-landscape-down {
    width: 100%;
  }
}

.settings-tabs > div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  //If we return to 6 tabs, we can reintroduce this flex below
  // current workaround has hard height set on each div - h-20, h-40
  // flex: 1 1 auto;


  box-sizing: content-box;

  & button {
    font-size: 1.4rem;
    height: 40%;
    border: none;
  }

  @include for-tablet-landscape-down {
    flex-direction: row;
    .general-button{
      font-size: 1rem;
    }
  }

  @include for-small-phone-down {
    .general-button{
      font-size: 0.7rem;
    }

    .general-button-dynamic{
      padding: 8px 11px 8px 11px;
    }
  }
}


.tab-content {
  flex: 1 1 auto;
  width: 100%;

  padding: 0 10%;
  @include for-desktop-down {
      padding: 0 5%;
  }
  @include for-tablet-landscape-down {
      padding: 0;
  }
}
