.pick-a-specie {
  font-family: $spock;
  font-size: 1.4rem;
  color: lightgray;
  margin-bottom: 30px;
  @include for-tablet-landscape-down {
    font-size: 1rem;
  }

}
