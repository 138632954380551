.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: $lightgreen;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: $red;
}

.Toastify__toast-container {
  @extend .radius-8;
}

.Toastify__toast-body {
  font-family: $spock;
}
