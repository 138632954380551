.our-trees-section-4{
  .react-multi-carousel-item {
    &.align-center{
      @include for-tablet-landscape-down {
        align-items: baseline;
      }
    }
  }

  .read-more-popup h4 {
    @include for-tablet-landscape-down {
      font-size: 1.2rem;
    }
  }

  .read-more-popup p{
    @include for-tablet-landscape-down {
      // font-size: 0.7rem;
    }
  }
}