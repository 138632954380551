.faq-page-section-2-padding {
  padding-left: 5rem;
  padding-right: 5rem;

  @include for-tablet-landscape-down {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @include for-tablet-portrait-down {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.faq-item {
  font-family: $spock;
  position: relative;

  h3 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  div {
    font-size: 1.2rem;
    font-style: italic;
  }

  @include for-tablet-landscape-down {
    h3 {
      font-size: 1.2rem;
    }
    div {
      font-size: 0.9rem;
    }
  }
}

.faq-item-anchor {
  top: -310px;
  visibility: hidden;
  position: absolute;
}


.faqs-section-3-padding {
  padding-left: 120px;
  padding-right: 120px;

  @include for-desktop-down {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include for-tablet-landscape-down {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.contact-form {
  width: 460px;

  * {
    font-family: $spock;
    text-transform: uppercase;
    border: 0;
    outline: 0;
  }

  input, textarea {
    padding: 1rem;
    font-size: 1.2rem;
    font-style: italic;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: white;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: white;
    }
  }

  input[type=text], input[type=email] {
    height: 40px;
  }

  textarea {
    resize: none;
    height: 180px;
  }

  button {
    font-style: italic;
    font-size: 1.2rem;
    width: min-content;
    font-weight: 700;
  }


  @include for-desktop-down {
    width: 100%;
    input {
      font-size: 0.9rem;
    }
    textarea {
      font-size: 0.9rem;
    }
  }
}

.faqs-section-3-column-left-padding {
  @include for-tablet-portrait-down {
    padding: 0 4rem;

    h2 {
      margin-top: 0;
    }
  }
}

@include for-tablet-landscape-down {
  .faqs-section-3-wrap{
    .section-heading.mt-100 {
      margin-top: 0;
    }
  }
}

.faq__icon {
  height: 2rem;
}