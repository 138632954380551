.checkout-panel {
  z-index: 6;
  left: 0;
  top: 0;

  display: flex;
  justify-content: flex-end;

  > div {
    width: 100%;
  }

  select{
    padding: 15px 20px 12px 20px;
    border-radius: 8px;
    border: none;
    font-family: "SpockPro";
    font-weight: 800;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: none;

    > option {
      font-family: "SpockPro", sans-serif;
      font-weight: normal;
    }
  }

  @include for-tablet-portrait-down {
    justify-content: center;
    align-items: center;
    > div {
      border-radius: 8px;
      height: 90vh;
      width: 90vw;
      padding-right: 0;
    }
  }

  @include for-small-phone-down {
    > div {
      height: auto;
    }
  }
}

#card-radio-label, #paypal-radio-label {
  cursor: pointer;
  color: $forest;
}

#card-radio:checked + #card-radio-label {
  background-color: $lightgreen;
  color: white;
}

#paypal-radio:checked + #paypal-radio-label {
  background-color: $lightgreen;
  color: white;
}

.button {
    cursor: pointer;
    font-weight: 500;
    left: 3px;
    line-height: inherit;
    position: relative;
    text-decoration: none;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
}

.button--small {
    padding: 10px 20px;
    font-size: 0.875rem;
}

.button--green {
    outline: none;
    background-color: #64d18a;
    border-color: #64d18a;
    color: white;
    transition: all 200ms ease;
}

.button--green:hover {
    background-color: #8bdda8;
    color: white;
}

 
.guest-checkout-tab-email-wrap, .guest-login-form {
  img{
    filter: brightness(0) invert(1);
  }

  input{
    &::placeholder {
      color: white;
    }

    @include for-tablet-landscape-down {
      height: 60px;
      font-size: 1.2rem;
    }
  }
}

.register-tab-wrap{
  .register-tab-password-wrap{
    padding-right: 2px;
  }
  .register-tab-confirm-password-wrap{
    padding-left: 3px;
  }

  @include for-tablet-landscape-down {
    .register-tab-password-wrap{
      padding-right: 15px;
    }
    .register-tab-confirm-password-wrap{
      padding-left: 15px;
    }
  }
}


.braintree-sheet{
  border: none !important;
  
  .braintree-sheet__content {
    background-color: $lightgreen;
  }

  .braintree-sheet__header {
    background-color: $lightgreen;
    border: none;

    .braintree-sheet__text {
      color: white;
      margin-left: 0;
      font-size: 1.4rem;
      font-style: normal;
      font-family: "SpockPro";
    }

    .braintree-sheet__logo--header {
      display: none;
    }
  }
}

.braintree-sheet__error-text{
  font-size: 1.2rem !important;
  font-family: "SpockPro";
}

.braintree-sheet__content--form {
  input {
    background-color: $darkgreen;
    color: white;
  }

  .braintree-form__field-group {
    .braintree-form__label {
      color: white;
      font-size: 1.4rem;
      font-style: normal;
      font-family: "SpockPro";
    }

    .braintree-form__field{
      .braintree-form__hosted-field {
        height: 60px;
      }
    } 
  }
}

.braintree-form__hosted-field { 
  border: none !important;
  background-color: $darkgreen;
  border-radius: 8px;
}

.braintree-placeholder, 
.braintree-heading,
.braintree-large-button.braintree-toggle,
.braintree-form__descriptor,
.braintree-methods.braintree-methods-initial {
  display: none;
}

.add-payment-method-loader {
  position: absolute;
  top: 2.7em;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #8cc871;
}

.braintree-methods--active:not(.braintree-methods--edit) .braintree-method--active {
  background-color: $darkgreen;
}

.braintree-sheet--has-error .braintree-sheet__error {
  background-color: $lightgreen;
  margin-top: 0;
}

.braintree-method .braintree-method__label{
  color: white
}

// override default FAQBox
.payment-method-item{
  div{
    &.flex{
      justify-content: center;
    } 
  }

  @include for-small-phone-down {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

// custom class from BrainTree js create configuration
.braintree-input-class {
  color: white;
  font-weight: 800;
  font-size: 1.2rem;
  font-family: 'SpockPro', 'Roboto', sans-serif;
  padding: 8px;
}

.checkout-summary{
  padding-left: 5%;

  @include for-big-desktop-up {
    .cart-item-prices{
      .large-text-p{
        font-size: 1.2rem;
      }
    }
  }

  @include for-extra-extra-large-up {
    .cart-item-prices{
      .large-text-p{
        font-size: 1.5rem;
      }
    }
  }

  @include for-big-desktop-down {
    .cart-item-prices{
      .normal-text-p{
        font-size: 1rem;
      }

      .large-text-p{
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }

  @include for-desktop-down {
    .cart-item-prices{
      .large-text-p{
        font-size: 1.5rem;
      }
    }
  }

  @include for-desktop-down {
    border-left: none;
    border-top: 4px solid white;
    padding-left: 0;
    padding-right: 0;
    padding-top: 2%;
    margin-left: 5%;
  }

  @include for-tablet-landscape-down {
    margin-left: 0;
  }
}

.checkout-panel-wrap{
  &.bigger-page-padding {
    @include for-big-desktop-down {
      padding-left: unset;
    }

    @include for-desktop-down {
      padding-right: 4rem;
    }

    @include for-tablet-landscape-down {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  #remember-me:checked ~ .fake-checkbox:after {
    border-left: 4px solid $darkgreen;
    border-bottom: 4px solid $darkgreen;
  }

  @include for-tablet-landscape-down {
    input[type="number"] {
      width: 3rem;
    }
  }
}


.payment-form-wrap {
  width: 500px;
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
}
.payment-form-wrap.animate {  
  max-height: 523px;
}

// This class below is a class that all react-select components have
.css-1nmdiq5-menu{
  color:black;
}

#root .register-tab-wrap {
    .css-13cymwt-control,
      .css-t3ipsp-control {
        background-color: $darkgreen;
      }
    
      .css-1xc3v61-indicatorContainer svg {
        color: white;
      }
      .css-1jqq78o-placeholder, .css-1xc3v61-indicatorContainer:hover {
          color: white !important;
      }
      .css-1jqq78o-placeholder {
        color: white;
      }

      .css-1dimb5e-singleValue {
        color:white;
      }
}