.text-block-heading {
  font-size: 1.6rem;
  font-weight: 800;
  @include for-big-desktop-down {
    font-size: 1.4rem;
  }
  @include for-desktop-down {
    font-size: 1.2rem;
  }
  @include for-tablet-landscape-down {
    font-size: 1.1rem;
  }
  @include for-tablet-portrait-down {
    font-size: 1rem;
  }
}

.dynamic-padding-right {
  padding-right: 20px;
  @include for-tablet-landscape-down {
    padding-right: 60px;
  }
}
