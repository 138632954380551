.bg-image-settings {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.landing-slide-1 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/landing_page/bg-landing-slide-1_idkwse.jpg');
}

.landing-slide-2 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/landing_page/bg-landing-slide-2_oykqvh.jpg');
}

.landing-slide-3 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/landing_page/bg-landing-slide-3_isxn6o.jpg');
}

.bg-landing-page-section-5 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/Background-Section-5_e70pl9.webp');
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: -30px;
  background-position: center center;

  @include for-desktop-down {
    padding-bottom: 3rem;
  }
}

.bg-landing-page-section-8 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/Background-section-8_aghkb9.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-about-page-section-1 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-about-page-section-1_osr4h1.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-about-page-section-3 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-about-page-section-3_ctihju.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-about-page-section-5 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-about-page-section-5_n4lwug.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-about-page-section-6 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-about-page-section-6_pbikxh.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-our-trees-section-1 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-our-trees-section-1_sre799.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-our-trees-section-4 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-our-trees-section-4.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.bg-wildlife-page-section-1 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-wildlife-page-section-1.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70%;
}

.bg-wildlife-page-section-3 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-wildlife-page-section-3_phhv7r.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-wildlife-page-section-4 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-wildlife-section-4.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.bg-native-store-page-slide-1 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-native-store-page-slide-1_ipqecw.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-native-store-page-slide-2 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-native-store-page-slide-2_llu6lf.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-native-store-page-slide-3 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-native-store-page-slide-3_gzz7em.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-native-store-page-section-3 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-native-store-page-section-3_qwr1l0.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-native-store-page-section-5 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-native-store-page-section-5_nyfopm.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-native-store-section-6 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/native_store/bg-native-store-section-6.png');
}

.bg-login {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-login_oyacm3.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-register {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-register-page_vj8n3g.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-thank-you {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/login/bg-thank-you_lvycry.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-our-projects {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-our-projects-page.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-our-projects-section-4 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-our-projects-section-4_fm0u9w.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-our-projects-section-5 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/our_projects/bg-our-projects-section-5_pfwj1u.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-faqs-section-1 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-contact-section-1_ibrduu.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-faqs-section-3 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-faq-page-section-3_fhitwr.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

.bg-user-profile-header-image {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/header-1.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-settings-section-1 { 
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/header-1.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-purchase-history {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/header-1.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-contact-us {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-contact-us_py9xey.webp');
  background-size: cover;
}

.bg-contact-us-section-4 {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/contact-page-frog.webp');
  background-size: cover;
}

.bg-privacy-policy {
  background-image: url('https://leaf-static-assets.s3.us-east-2.amazonaws.com/webp/bg-privacy-policy_fkbe9e.webp');
}