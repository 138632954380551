.font-family-noto {
  font-family: $noto !important;
}

.font-family-spock {
  font-family: $spock !important;
}

.weight-100 {
  font-weight: 100;
}

.weight-200 {
  font-weight: 200;
}

.weight-300 {
  font-weight: 300;
}

.weight-400 {
  font-weight: 400;
}

.weight-700 {
  font-weight: 700;
}

.weight-800 {
  font-weight: 800;
}


.text-centered {
  text-align: center;
}

.text-right {
  text-align: right;
}


.font-italic {
  font-style: italic;
}

.font-normal {
  font-style: normal !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

// .1-line-ellipsis {
//   text-overflow: ellipsis;
//   /* Needed to make it work */
//   overflow: hidden;
//   white-space: nowrap;
// }

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.validations {
  font-size: 0.8rem;
  @include for-tablet-landscape-down {
    font-size: 0.7rem;
  }
}


// Trying to introduce a better text system, instead of custom coding text sizes for each component through the site...


.very-small-text-p {
  font-size: 0.8rem;
  @include for-desktop-down {
    font-size: 0.7rem;
  }
  @include for-tablet-landscape-down {
    font-size: 0.6rem;
  }

  @include for-tablet-portrait-down {
    font-size: 0.5rem;
  }
}

.form-error {
  @extend .very-small-text-p;
  color: $red;
  margin: 0;
  padding-top: 5px;
}

.small-text-p {
  font-size: 1rem !important;
  @include for-desktop-down {
    font-size: 0.9rem !important;
  }
  @include for-tablet-landscape-down {
    font-size: 0.8rem !important;
  }

  @include for-tablet-portrait-down {
    font-size: 1rem !important;
  }
}

.normal-text-p {
  font-size: 1.2rem;
  @include for-desktop-down {
    font-size: 1.1rem;
  }
  @include for-tablet-landscape-down {
    font-size: 1rem;
  }
  @include for-tablet-portrait-down {
    font-size: 0.9rem;
  }
}

.large-text-p {
  font-size: 1.5rem;

  @include for-desktop-down {
    font-size: 1.3rem;
  }

  @include for-tablet-landscape-down {
    font-size: 1.2rem;
  }

  @include for-tablet-portrait-down {
    font-size: 1rem;
  }
}

.very-large-text-p {
  font-size: 1.8rem;

  @include for-desktop-down {
    font-size: 1.6rem;
  }

  @include for-tablet-landscape-down {
    font-size: 1.4rem;
  }

  @include for-tablet-portrait-down {
    font-size: 1.2rem;
  }
}

@include for-desktop-down {
  .flip-card-back{
    .very-large-text-p {
      font-size: 1rem;
    }

    .general-button {
      font-size: 0.7rem;
    }
  }
}


.wrap-text {
  white-space: pre-wrap;
}

.letter-spacing-0_05 {
  letter-spacing: 0.05rem;
}

