.dynamic-viewport-height {
  height: 100vh;
  @include for-tablet-landscape-down {
    height: 600px;
  }
}

.dynamic-map-height-location-profile {
  height: 100vh;
  @include for-tablet-landscape-down {
    height: 300px;
    transform: translateX(-15px);
    width: 100vw !important;
  }
}
