.user-registration-box {
  font-family: $spock;
  width: 750px;
  margin-left: 40px;
  @include for-extra-extra-large-up {
    margin-top: 12% !important;
  }
  @include for-extra-extra-large-down {
    margin-top: 12% !important;
  }
  @include for-big-desktop-down {
    width: 650px;
  }
  @include for-desktop-down {
    width: 550px;
  }
  @include for-tablet-landscape-down {
    width: 500px;
    margin-left: 30px;
  }
  @include for-tablet-portrait-down {
    width: 90%;
    margin: 0 auto;
  }
  @include for-tablet-landscape-down {
    margin-top: 15% !important;
  }
  @include for-small-phone-down {
    margin-top: 20% !important;
  }
}

.dynamic-margin-top {
  margin-top: 20px;
  @include for-tablet-landscape-down {
    margin-top: 5px;
  }
}

.register-confirmation {
  display: block;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.2;
  @include for-tablet-landscape-down {
    font-size: 0.7rem;
  }
  @include for-tablet-portrait-down {
    font-size: 0.6rem;
  }
}

.user-registrations-checkbox {
  margin-bottom: 5px;
  @include for-tablet-portrait-down {
    margin-bottom: 10px;
  }
}

.user-registrations-checkbox > input[type=checkbox] {
  visibility: hidden;
}

.fake-register-checkbox {
  left: 0;
  top: 0.4rem;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  background-color: white;
  cursor: pointer;
  transform: translateY(-50%);
}

.user-registrations-checkbox > input[type=checkbox]:checked ~ .fake-register-checkbox:after {
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  border-radius: 4px;
  height: 70%;
  width: 70%;
  background-color: $lightgreen;
}
