.animal-slide-size {
  max-width: 600px;
  max-height: 600px;
  min-width: 600px;
  min-height: 600px;
  width: 600px;
  height: 600px;

  @include for-big-desktop-down {
    max-width: 500px;
    max-height: 500px;
    min-width: 500px;
    min-height: 500px;
    width: 500px;
    height: 500px;
  }

  @include for-tablet-landscape-down {
    max-width: 350px;
    max-height: 350px;
    min-width: 350px;
    min-height: 350px;
    width: 350px;
    height: 350px;
  }

  @include for-tablet-portrait-down {
    max-width: 80vw;
    max-height: 80vw;
    min-width: 80vw;
    min-height: 80vw;
    width: 80vw;
    height: 80vw;
  }
}

.tree-slide-size {
  max-width: 470px;
  min-width: 470px;
  max-height: 630px;
  min-height: 630px;

  @include for-desktop-down {
    max-width: 400px;
    min-width: 400px;
    max-height: 600px;
    min-height: 600px;
  }

  @include for-tablet-portrait-down {
    max-width: 80vw;
    min-width: 80vw;
    max-height: 110vw;
    min-height: 110vw;
  }
}


.tree-slide-size-squared {
  @include for-desktop-down {
    max-width: 470px;
    min-width: 470px;
    max-height: 470px;
    min-height: 470px;
  }

  @include for-tablet-portrait-down {
    max-width: 80vw;
    min-width: 80vw;
    max-height: 80vw;
    min-height: 80vw;
  }
}

.tree-shorter {
  @include for-desktop-down {
    max-width: 360px;
    min-width: 360px;
    max-height: 480px;
    min-height: 480px;
  }

  @include for-tablet-portrait-down {
    max-width: 80vw;
    min-width: 80vw;
    max-height: 100vw;
    min-height: 100vw;
  }
}
