.inner-header-image-height {
  height: 270px;
}

.header-image-height {
  height: 500px;

  @include for-desktop-down {
    height: 400px;

  }
  @include for-tablet-landscape-down {
    height: 300px;

  }

  &::after {
    position: absolute;
    z-index: 0;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $darkgreen;
    opacity: 0.5;
  }
}

.user-profile-image {
  pointer-events: auto;
  height: 300px;
  width: 300px;
  min-height: 300px;
  min-width: 300px;
  @include for-desktop-down {
    height: 250px;
    width: 250px;
    min-height: 250px;
    min-width: 250px;
  }
  @include for-tablet-landscape-down {
    height: 200px;
    width: 200px;
    min-height: 200px;
    min-width: 200px;
  }

  @include for-tablet-portrait-down {
    height: 130px;
    width: 130px;
    min-height: 130px;
    min-width: 130px;
  }
}

.upload-profile-photo-button {
  font-size: 1rem;
  font-weight: 400;


  @include for-desktop-down {
    font-size: 0.8rem;
    font-weight: 400;
  }
  @include for-tablet-landscape-down {
    font-size: 0.7rem;
    font-weight: 400;
  }

  @include for-tablet-portrait-down {
    font-size: 0.6rem;
    font-weight: 400;
  }
}

.user-profile-area {
  pointer-events: none;
  z-index: 1;
  padding: 0 8% 0 8%;
  bottom: -150px;



  @include for-desktop-down {
    bottom: -120px;
  }
  @include for-tablet-landscape-down {
    padding: 0 7% 0 7%;
    bottom: -90px;
  }

  @include for-tablet-portrait-down {
    padding: 0 6% 0 6%;
    bottom: -60px;
  }
}

.user-profile-content-margin {
  padding-right: 3rem;
  margin-bottom: 140px;

  @include for-desktop-down {
    margin-bottom: 120px;
  }
  @include for-tablet-landscape-down {
    margin-bottom: 100px;
    padding-right: 2rem;
  }
  @include for-tablet-portrait-down {
    margin-bottom: 80px;
    padding-right: 1rem;
  }
  @include for-small-phone-down {
    padding-top: unset;
    margin-top: 8vw;
    margin-bottom: 8vw;
    padding-right: unset;

    div.justify-content-end{
      justify-content: center !important;
    }
  }
}

.user-profile-name {
  font-size: 3rem;
  margin-left: 3rem;

  @include for-big-desktop-down {
    font-size: 2.5rem;
  }
  @include for-desktop-down {
    font-size: 2rem;
  }
  @include for-tablet-landscape-down {
    font-size: 1.7rem;
    margin-bottom: 10px;
    margin-left: 2rem;
  }
  @include for-tablet-portrait-down {
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-bottom: 0.2rem;
  }
}

.user-profile-subtitle-margin {
  margin-left: 3rem;

  @include for-tablet-landscape-down {
    margin-left: 2rem;
  }
  @include for-tablet-portrait-down {
    margin-left: 1rem;
  }
}

.profile-general-tab-wrap{
  select{
    width: 100%;
    margin-top: 0.5rem;

    @include for-extra-extra-large-up {
      max-width: 60%;
    }
  }

@include for-small-phone-down {
   >div{
    >.flex:not(:first-of-type){
      flex-direction: column;
      
      &.mb-20{
        margin-bottom: unset;

        label{
          margin-top: 1.5rem;
        }
      }
    }
   }   
  }
}

.my-trees-tab-wrap,
.my-orders-tab-wrap,
.user-orders-trees-page-wrap {
  @include for-small-phone-down {
    .section-padding {
      padding: 0 !important;

      .container-fluid {
        padding: 0;

        .container {
          padding: 0;

          .col-6:nth-child(odd){
            padding-right: 0;
          }

          .col-6:nth-child(even){
            padding-left: 0;
          }
        }
      }
    }
  }
}
