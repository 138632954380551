.our-trees-subscription-section-wrap{
  .row{
    .col-6{
      @include for-desktop-down {
        margin-bottom: 9rem;
      }

      @include for-tablet-landscape-down{
        margin-bottom: 3rem;
      }

      @include for-small-phone-down {
        margin-bottom: 2rem;
      }
    }
  }
}

.subscription-card {
  position: relative;
  height: 500px;
  width: 260px;
  font-family: $spock;

  @include for-big-desktop-down {
    height: 110%;
    width: unset;
  }
 
  @include for-desktop-down {
    height: 90%;
    margin-bottom: 80px;
  }

  @include for-tablet-landscape-down {
    height: 440px;
  }

  @include for-tablet-portrait-down {
    height: 340px;
    width: 43vw;
  }
}

.subscription-card-image {
  width: 220px;

  @include for-big-desktop-down {
    width: 190px;
  }
  @include for-tablet-portrait-down {
    width: 32vw;
  }
}

.subscription-discount {
  z-index: 1;
  width: 200px;
  height: 65px;
  position: absolute;
  top: -50px;
  right: -15px;
  font-family: $spock;
  text-transform: uppercase;

  p {
    margin-top: 10px;
    font-size: 0.9rem;
    font-weight: 600;
  }
  h3 {
    font-size: 1.8rem;
    font-weight: 600;
    font-style: italic;
    line-height: 1;
    letter-spacing: 0.5rem;
  }

  @include for-big-desktop-down {
    width: 160px;
    height: 55px;
    top: -50px;

    p {
      margin-top: 8px;
      font-size: 0.8rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }

  @include for-tablet-portrait-down {
    width: 28vw;
    height: 44px;
    top: -40px;
    right: -10px;

    p {
      margin-top: 5px;
      font-size: 0.6rem;
    }
    h3 {
      font-size: 0.9rem;
    }
  }
}

.subscription-card-price {
  font-size: 3.2rem;
  font-weight: 800;
  line-height: 0.8;
  margin-top: 30px;

  @include for-big-desktop-down {
    font-size: 2.8rem;
  }
  @include for-tablet-portrait-down {
    margin-top: 20px;
    font-size: 2.1rem;
  }
}

.subscription-card-frequency {
  font-family: $noto;
  font-style: italic;
  font-weight: 900;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  margin-bottom: 20px;

  @include for-big-desktop-down {
    font-size: 0.9rem;
  }
  @include for-tablet-portrait-down {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }
}

.subscription-card-heading {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.6rem;
  margin-top: 10px;

  @include for-big-desktop-down {
    font-size: 1.4rem;
  }
  @include for-tablet-portrait-down {
    padding: 0 10px;
    margin-top: 10px;
    line-height: 1;
    font-size: 1.2rem;
  }
}

.subscription-card-text {
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.1;
  margin-top: 10px;
  strong {
    font-weight: 900;
  }
  @include for-big-desktop-down {
    font-size: 0.8rem;
  }
  @include for-tablet-portrait-down {
    margin-top: 5px;
    font-size: 0.7rem;
  }
}

.user-newsletter {
  display: flex;
  height: auto !important;
  min-height: auto !important;
  justify-content: center;

  button {
      color: white;
      text-transform: uppercase;
      padding: 0.5rem 2rem;
      font-size: 1rem;
      font-weight: bold;
      position: static !important;
      height: auto !important;
    }
    
    .subscribe-label {
      margin-right: 10px;
    }
}