.chart-bars-container {
  padding:3px; 
  &> div {
    margin-bottom: 6px;

  }
}

.importance-chart-container{
  .chart-bars-container{
    @include for-desktop-down {
      width: 90% !important;
    }
  }
}

.importance-chart-image-container {
  height: 32px;
  width: 32px;
  @include for-tablet-portrait-down {
    height: 26px;
  }
}

.importance-chart-image {
  height: 24px;
  @include for-tablet-portrait-down {
    height: 15px;
  }
}

.chart-bar {
  position: relative;
  height: 24px;
  margin-left: 2px;
  & > span {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 100%;
    transition: 0.2s;
  }
  & p {
    height: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
    display: flex;
    align-items: center;
    padding-left: 5px;
    font-family: $spock;
    font-size: 0.6rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1rem;
    @include for-tablet-portrait-down {
      font-size: 6px;
    }
  }
}



.tree-of-month .info-chart-height {
  height:220px;
  @include for-tablet-portrait-down {
      height:170px;
    }
}

.info-chart-height {
  height: 180px;
  @include for-tablet-portrait-down {
    height: 140px;
  }
}

.info-chart-heading {
  font-family: $spock;
  text-transform: uppercase;
  font-style: italic;
  letter-spacing: 0.05rem;
  font-size: 1rem;
  @include for-tablet-landscape-down {
    font-size: 0.8rem;
  }
  @include for-tablet-portrait-down {
    font-size: 0.6rem;
  }
}


.associated-wildlife-size {
  width: 320px;
}

.importance-size {
  width: 420px;
}

.info-chart-icon {
  height: 40px;
  @include for-tablet-landscape-down {
    height: 30px;
  }
  @include for-tablet-portrait-down {
    height: 20px;
  }
}

.card-chart {
  margin-bottom: 6px;

  h3 {
    font-size: 7px;
    margin-top: 4px;
    margin-bottom: 5px;
  }

  .importance-chart-image-container {
    height: 21px;
    width: 30px;
  }

  .importance-chart-image {
    height: 18px;
  }

  .chart-bar {
    height: 21px;
    p {
      font-size: 8px;
    }
  }

  .info-chart-icon {
    height: 20px;
  }
}

.card-chart-height {
  height: auto;
}

.database-card-chart {
  h3 {
    font-size: 0.6rem;
    margin-bottom: 0;
    @include for-desktop-down {
      font-size: 0.5rem;
    }
    @include for-tablet-landscape-down {
      font-size: 0.4rem;
    }
    @include for-tablet-portrait-down {

    }
  }

  .importance-chart-image-container {
    height: 24px;
    width: 24px;
    @include for-desktop-down {
      height: 19px;
      width: 19px;
      min-width: 19px;
    }
    @include for-tablet-landscape-down {
      height: 16px;
      width: 16px;
      min-width: 16px;
    }
    @include for-tablet-portrait-down {

    }
  }

  .importance-chart-image {
    height: 18px;
    @include for-desktop-down {
      height: 14px;
    }
    @include for-tablet-landscape-down {
      height: 12px;
    }
    @include for-tablet-portrait-down {
      height: 10px;
    }
  }

  .chart-bar {
    height: 20px;
    @include for-big-desktop-down {
      height: 18px;
    }
    @include for-desktop-down {
      height: 14px;
      p {
        font-size: 8px;
      }
    }
    @include for-tablet-landscape-down {
      height: 12px;
    }
    @include for-tablet-portrait-down {
      height: 10px;
    }
  }

  .info-chart-icon {
    height: 36px;
    @include for-big-desktop-down {
      height: 26px;
    }
    @include for-desktop-down {
      height: 20px;
    }
    @include for-tablet-landscape-down {
      height: 20px;
    }
    @include for-tablet-portrait-down {
      height: 16px;
    }
  }
}

