.user-profile-map-size {
  height: 400px;
  min-height: 400px;
}

.responsive-location-tab-padding {
  padding: 3rem 4rem 3rem 4rem;
  @include for-tablet-landscape-down {
    padding: 3rem 2rem 3rem 2rem;
  }
  @include for-tablet-portrait-down {
    padding: 3rem 1rem 3rem 1rem;
  }
}
