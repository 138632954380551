.side-menu {
  pointer-events: auto;
  position: fixed;
  overflow-y: scroll;
  overscroll-behavior: contain;
  z-index: 5;

  height: 100%;
  width: 380px;
  padding-right: 60px;
  right: -380px;

  background-color: #8cc871ee;
  font-family: $spock;

  transition: 0.6s;
  transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);

  @include for-tablet-portrait-down {
    width: calc(100vw + 60px);
    right: calc(-100vw - 60px);
  }

  & a {
    color: white;
    text-decoration: none;
    font-size: 24px;
    letter-spacing: 4px;
    transition: 0.2s;
  }

  &__icon {
    height: 2rem;
  }
}

.open-side-menu {
  right: -60px;
}

.side-menu-content {
  margin: 0 30px 0 30px;
  height: 100%;
}

.nav-links {
  margin-top: 70px;
  text-transform: uppercase;
  & > a {
    font-weight: 400;
    margin-bottom: 1rem;
    line-height: 1.2;
  }
  & > a:hover{
    transform: skewX(-10deg);
  }
}

.session-links {
  & > a:first-child {
    font-size: 22px;
    &:hover {
      transform: skewX(-10deg);
    }
  }
  & > a:last-child {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 5px;
    font-size: 26px;
  }
}

.follow-us {
  font-size: 14px;
  letter-spacing: 4px;
  font-style: italic;
  text-transform: uppercase;
  & > h2 {
    font-weight: 400;
    margin-bottom: 0;
  }
}

.social-icons {
  &>a{
    margin: 10px;
    background-color: $darkgreen;
    padding: 4px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.icon{
    width: 40px;
    height: 40px;
  }
}


//animation keyframes
