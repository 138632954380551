.responsive-update-padding {
  padding: 4rem 5rem 3rem 5rem;

  @include for-desktop-down {
    padding: 3rem 4rem 3rem 4rem;
  }

  @include for-tablet-landscape-down {
    padding: 3rem 3rem 1rem 3rem;
  }

  @include for-tablet-portrait-down {
    padding: 3rem 2rem 1rem 2rem;
  }
}

.responsive-inner-padding {
  padding-left: 3rem;

  @include for-tablet-landscape-down {
    padding-left: 1rem;
  }

}
