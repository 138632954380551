.react-multi-carousel-list {
    position: relative;
    width: 300px;
}

.multi-carousel-container {
  width: 100%;
  height: 350px;
  overflow: visible;

  &.home-carousel {
    height:455px;
  }
}

.controlled-carousel-container {
  min-height: 400px;
  width: 85%;
  overflow-x: clip;

  @include for-desktop-down {
    // width: 110% !important;
    min-height: unset;
  }

  @include for-small-phone-down {
    width: 100% !important;
  }
}

.about-us-section-7-wrap {
  .controlled-carousel-container {
    @include for-desktop-down{
      width: 90% !important;
    }
  }
}

.dynamic-card-height {
  min-height: 300px;
  @include for-desktop-down {
    min-height: 200px;
    height: 200px;
   
  }
}

.wildlife-carousel-wrap,
.trees-profile-section-2,
.native-store-section-2 {
  .dynamic-card-height {
    @include for-desktop-down {
      min-height: unset;
      height: unset;
    }
  }

  .dynamic-height-pop-up{
    @include for-desktop-down {
      height: unset;
    }
  }

  .section-heading {
    @include for-small-phone-down {
      padding: 0;
      background-color: unset;
    }
  }
}

.wildlife-profile-page{
  h3{
    @include for-desktop-down {
      margin-bottom: unset !important;
    }
  }
}

.custom-arrow {
  position: absolute !important;
  z-index: 1;
  border: 1px solid white;
  border-width: 0 10px 10px 0;
  display: inline-block;
  padding: 15px;
  cursor: pointer;
  height: 50px;
  width: 50px;

  @include for-small-phone-down {
    visibility: hidden;
  }

  @include for-desktop-down {
    height: 30px;
    width: 30px;
  }
  @include for-tablet-landscape-down {
    border-width: 0 7px 7px 0;
    padding: 10px;
    height: 10px;
    width: 10px;
  }
}

.custom-right-arrow {
  right: 12px;
  transform: rotate(-45deg) skew(6deg, 6deg);
  @include for-tablet-landscape-down {
    right: 8px;
  }
}

.custom-left-arrow {
  left: 12px;
  transform: rotate(135deg) skew(6deg, 6deg);

  @include for-tablet-landscape-down {
    left: 8px;
  }
}

.landing-page-section-3,
.about-us-section-7-wrap,
.native-store-section-2,
.wildlife-carousel-wrap {
  .controlled-carousel-container {
    @include for-extra-extra-large-down {
      width: 100%;
    }
    @include for-small-phone-down {
      width: 75% !important;
    }
  }
}

@include for-small-phone-down {
  .landing-page-section-3, 
  .about-us-section-7-wrap,
  .native-store-section-2,
  .wildlife-carousel-wrap { 
    .custom-arrow {
      visibility: visible;
    }
  }
}

.controlled-carousel-container.mb-30.w-90{
  min-height: 0;
 }