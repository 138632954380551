.our-trees-section-2{
    @include for-desktop-down {
        div.container{
            margin-bottom: 0 !important;
        }
    }

    @include for-tablet-landscape-down{
        >div{
            >div{
                .mb-40{
                    margin-bottom: unset;
                }
            }
        }
    }

    @include for-small-phone-down {
        .section-heading{
            margin-bottom: 0;
        } 

        >div{
            >div.mt-20{
                margin-top: 0;
            }
        }
    }
}


