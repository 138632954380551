.our-projects-text-box-size {
  width: 600px;
  bottom: 50px;
  right: 8%;
  @include for-big-desktop-down {
    width: 500px;
  }
  @include for-desktop-down {
    width: 400px;
  }
  @include for-tablet-landscape-down {
    width: 60%;
  }
  @include for-tablet-portrait-down {
    width: 75%;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  @media (max-width: 645px) {
    bottom: -35px;
  }
}

@media (max-width: 645px) {
  .our-projects-section-2-wrap{
    margin-bottom: 35px;
  }
}

.our-projects-image-size {
  width: 800px;
  top: 70px;
  left: 10%;
  object-position: left;

  @include for-big-desktop-down {
    width: 700px;
  }
  @include for-desktop-down {
    width: 600px;
  }
  @include for-tablet-landscape-down {
    width: 75%;
    height: 50%;
  }
  @include for-tablet-portrait-down {
    width: 70%;
    height: 70%;
  }
}
