.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.map-box-component {
  overflow: hidden;
}

.mapbox-popup {
  font-family: $spock;
  font-size: 1rem;
  font-weight: 600;
  font-style: italic;
  text-trasnform: uppercase;
  color: $darkgreen;
  border-radius: 8px;
  .mapboxgl-popup-content {
    background-color: $lightgreen;
    padding: 10px 10px !important;
  }
  .mapboxgl-popup-tip {
    border-top-color: $lightgreen !important;
    border-bottom-color: $lightgreen !important;
  }
}

// Google maps
.name-tag {
  background-color: #8cc871;
  border-radius: 4px;
  color: #000;
  font-size: 12px;
  padding: 10px 15px;
  position: absolute;
  font-weight: bold;
  white-space: nowrap;
  transform: translate(-51%, -188%);
}

.name-tag::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -4%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #8cc871;
}

.pin{
  width: 24px;
  height: 24px;
  border-radius: 50% 50% 50% 0;
  background: #278583;
  position: absolute;
  transform: translate(-48%,-115%) rotate(-45deg);
  left: 50%;
  top: 50%;
 
}
.pin::after {
    content: '';
    width: 12px;
    height: 12px;
    margin: 6px;
    background: #ffffff;
    position: absolute;
    border-radius: 50%;
}
