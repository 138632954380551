/* The switch - the box around the slider */
//$height taken from header

.language-toggle {
  pointer-events: auto;
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
}

.toggle-language {
  margin: 0;
  padding: 0px 8px 0 8px;
  z-index: 1;
  font-size: 14px;
  font-family: $spock;
  font-weight: 700;
}

/* Hide default HTML checkbox */
.language-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
  background-color: $forest;
}

/* The slider */
.toggle-slider {
  box-shadow: 2px 3px 4px rgba(0,0,0,0.2);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $forest;
  -webkit-transition: .1s;
  transition: .1s;
  -webkit-transition-timing-function: ease-in;
    -moz-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.toggle-slider:after {
  z-index: 2;
  position: absolute;
  content: "";
  height: $height;
  width: $width/1.8;
  background-color: white;
  -webkit-transition: .1s;
  transition: .1s;
  -webkit-transition-timing-function: ease-in;
    -moz-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

input:checked + .toggle-slider {
  background-color: #ffd166;
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px #ffd166;
}

input:checked + .toggle-slider:after {
  -webkit-transform: translateX($width/2.2);
  -ms-transform: translateX($width/2.2);
  transform: translateX($width/2.2);
}

/* Rounded sliders */
.toggle-slider.round {
  border-radius: 8px;
}

.toggle-slider.round:after {
  border-radius: 8px;
}
