$columns: 12;
$rows: 7;

$column-width: 6;
$row-height: 11;

$column-gap-calculator: ( ( 100 / ($columns) ) - $column-width );
$row-gap-calculator: ( ( 100 / ($rows) ) - $row-height );

.location-profile-section-3 {
  @include for-extra-extra-large-down {
    .narrow-button {
      padding: 8px 10px 6px 10px;
    }

    .general-button {
      font-size: 0.9rem;
    }
  }

  @include for-small-phone-down {
    .pt-80{
      padding-top: 0;
    }
  }
}

.iconic-wildlife-grid-size {
  width: 90vw;
  min-height: 600px;
  height: 90vh;
}

.iconic-wildlife-container {
  display: grid;

  grid-template-columns: repeat($columns, percentage($column-width / 100));
  grid-template-rows: repeat($rows, percentage($row-height / 100));

  column-gap: percentage( $column-gap-calculator / 100 );
  row-gap: percentage( $row-gap-calculator / 100 );

  @include for-big-desktop-down {
    .general-button {
      font-size: 1em;
    } 
  }

  @include for-small-phone-down {
    .general-button {
      font-size: .8em;
      text-align: center;
      justify-content: center;
    }

    grid-template-rows: repeat(1, percentage($row-height / 100));
    row-gap: 1.286%;
  }
}

.grid-heading-underlay {
  top: 3px;
  left: 3px;
  text-shadow: 6px 7px 7px rgba(0,0,0,0.3);
}

.grid-heading {
  grid-column: 4 / 10;
  grid-row: 1 / 4;
}

.grid-heading-mobile {
  grid-column: 1 / 13;
  grid-row: 1 / 3;
}

.iconic-wildlife-grid-1 {
  grid-column: 1 / 4;
  grid-row: 1 / 6;
}

.iconic-wildlife-grid-1-mobile {
  grid-column: 7 / 13;
  grid-row: 6 / 8;
}


.iconic-wildlife-grid-2 {
  grid-column: 10 / 13;
  grid-row: 1 / 4;
}

.iconic-wildlife-grid-2-mobile {
  grid-column: 1 / 7;
  grid-row: 6 / 8;
}


.iconic-wildlife-grid-3 {
  grid-area: 4 / 4 / 6 / 7;
}

.iconic-wildlife-grid-3-mobile {
  grid-column: 1 / 7;
  grid-row: 3 / 5;

  @include for-small-phone-down {
    grid-row: 3 / 4;
  }
}


.iconic-wildlife-grid-4 {
  grid-area: 6 / 1 / 8 / 5;

  @include for-big-desktop-down {
    grid-column: 1 / 4;
  }
}

.iconic-wildlife-grid-4-mobile {
  grid-column: 1 / 4;
  grid-row: 5 / 6;

  @include for-small-phone-down {
    grid-row: 4 / 5;
    grid-column: 1 / 7;
  }
}

.iconic-wildlife-grid-5 {
  grid-column: 7 / 13;
  grid-row: 4 / 8;
}

.iconic-wildlife-grid-5-mobile {
  grid-column: 7 / 13;
  grid-row: 3 / 6;
}

.iconic-wildlife-grid-6 {
  grid-area: 6 / 5 / 8 / 7;

  @include for-big-desktop-down {
    grid-column: 4 / 7;

    a {
      width: fit-content;
      margin: 0 auto;
    }
  }
}

.iconic-wildlife-grid-6-mobile {
  grid-column: 4 / 7;
  grid-row: 5 / 6;

  @include for-small-phone-down {
    grid-column: 1 / 7;
  }
}



