.dynamic-grid-1-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  @include for-desktop-down {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.environmental-benefits-button {
  display: block;
  height: 80px;
  min-width: 100px;
  @include for-desktop-down {
    height: 70px;
    min-width: 80px;
  }
}
