.profile-page .lazyload-wrapper {
  z-index: 2;
}

@include for-small-phone-down {
  .wildlife-profile-section-2-wrap,
  .native-store-section-2,
  .native-store-section-4 {
    div.row{
      div.mb-40{
        margin-bottom: 0;
      }
    }
  }
}

@include for-desktop-down {
  .native-store-section-2{
    .pt-80{
      padding-bottom: 80px;
    }
  }
}

.big-info-chart {
  height: auto;

  .importance-chart-image-container {
    height: 60px;
    width: 66px;
    margin-bottom: 10px;
    @include for-tablet-landscape-down {
      height: 30px;
      width: 33px;
    }
  }

  .importance-chart-image {
    height: 44px;
    @include for-tablet-landscape-down {
      height: 22px;
    }
  }

  .chart-bar {
    height: 40px;
    p {
      font-size: 0.8rem;
    }

    @include for-tablet-landscape-down {
      height: 20px;
      p {
        font-size: 0.6rem;
      }
    }
  }

  .info-chart-icon {
    height: 50px;
    @include for-tablet-landscape-down {
      height: 35px;
    }
  }

  .info-chart-icon-larger {
    height: 70px;
    @include for-tablet-landscape-down {
      height: 60px;
    }
    @include for-tablet-portrait-down {
      height: 50px;
    }
  }
}

.local-use-size {
  height: 380px;
  @include for-tablet-landscape-down {
    height: 250px;
  }
}

.big-info-chart-heading {
  margin-top: 10px;
  font-size: 1.6rem;

  @include for-desktop-down {
    font-size: 1.4rem;
  }
  @include for-tablet-landscape-down {
    font-size: 1.2rem;
  }
  @include for-tablet-portrait-down {
    font-size: 1rem;
  }
}

.subheading {
  font-weight: 500;
  font-family: $spock;
  font-size: 1.7rem;
  @include for-big-desktop-down {
    font-size: 1.5rem;
  }
  @include for-desktop-down {
    font-size: 1.4rem;
  }
  @include for-tablet-landscape-down {
    font-size: 1.3rem;
  }
  @include for-tablet-portrait-down {
    font-size: 1.2rem;
  }
}

.description-box-text {
  font-family: $spock;
  font-size: 1.7rem;
  font-weight: 300;
  padding-left: 80px;
  padding-right: 80px;
  @include for-big-desktop-down {
    font-size: 1.5rem;
  }
  @include for-desktop-down {
    font-size: 1.3rem;
    padding-left: 40px;
    padding-right: 40px;
  }
  @include for-tablet-landscape-down {
    font-size: 1.1rem;
  }
  @include for-tablet-portrait-down {
    font-size: 0.9rem;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.description-box-read-more {
  font-family: $spock;
  font-size: 1.5rem;
  font-weight: 800;
  background: transparent;
  border: none;
  &:focus {
    outline: none;
  }

  @include for-desktop-down {
    font-size: 1.2rem;
  }
  @include for-tablet-landscape-down {
    font-size: 1rem;
  }
  @include for-tablet-portrait-down {
    font-size: 0.8rem;
  }
}

.wildlife-consumption-icon {
  img {
    height: 100px;

    @include for-desktop-down {
      height: 80px;
    }
    @include for-tablet-landscape-down {
      height: 50px;
    }
    @include for-tablet-portrait-down {
      height: 30px;
    }
  }
  p {
    font-family: $spock;
    color: $forest;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include for-tablet-landscape-down {
      font-size: 0.6rem;
    }
  }
}

.grayscale-child-img img {
  filter: grayscale(100%);
}

.dynamic-gutter-left {
  padding-left: 0;
  @include for-desktop-down {
    padding-left: 0;
    padding-right: 0;
  }
}

.dynamic-gutter-right {
  padding-right: 0;
  @include for-desktop-down {
    padding-right: 0;
    padding-left: 0;
  }
}

.gallery-height {
  height: 570px;

  @include for-big-desktop-down {
    height: 520px;
  }
  @include for-desktop-down {
    height: 460px;
  }
  @include for-tablet-landscape-down {
    height: 400px;
  }
  @include for-tablet-portrait-down {
    height: 320px;
  }
  @include for-small-phone-down {
    height: auto;
  }
}

.fit-to-gallery {

  height: auto;
  max-height: 570px;
  width: auto;
  max-width: 80%;

  @include for-big-desktop-down {
    max-height: 520px;
  }

  @include for-desktop-down {
    max-height: 460px;
  }

  @include for-tablet-landscape-down {
    max-height: 400px;
  }
  
  @include for-tablet-portrait-down {
    max-height: 320px;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }

  @include for-small-phone-down {
    padding-left: 8vw;
    padding-right: 8vw;
  }
}

.profile-box-dynamic-width {
  width: 100vw;
  border-radius: 8px;
  box-shadow: 6px 7px 7px rgba(0,0,0,0.3);
  @include for-tablet-landscape-down {
    border-radius: 0;
    box-shadow: none;
  }
}

.our-projects-section-7-wrap {
  .profile-box-dynamic-width {
    border-radius: 0;
    box-shadow: none;
  }
}

.conservation-status-chart-boxes-container {
    width: calc(100%/10);
    margin-right: 5px;
    height: 11vw;
    max-height: 170px;

    @include for-desktop-down {
      margin-right: 4px;
      height: 12vw;
    }
    @include for-tablet-landscape-down {
      width: calc(100%/4);
      margin-right: 3px;
      height: 13vw;
    }
    @include for-tablet-portrait-down {
      margin-right: 2px;
      height: 14vw;
    }
  }

.conservation-status-chart-boxes {
  max-height: 80px;
  height: 6vw;

  p {
    font-family: $spock;
    font-size: 0.7rem;

    @include for-desktop-down {
      font-size: 0.52rem;
    }
    @include for-tablet-landscape-down {
      font-size: 9px;
    }
  }
  @include for-tablet-landscape-down {
    min-height: 9vw;
    max-height: 60px
  }
  @include for-tablet-portrait-down {
    min-height: 8vw;
    max-height: 60px;
    padding:  0 !important;
  }
}

.no-info {
  background-color: #666666;
}

.safe {
  background-color: $forest;
}

.endangered {
  background-color: #dc2613;
}

.extinct {
  background-color: black;
}

.no-info-text {
  color: #666666;
}

.safe-text {
  color: $forest;
}

.endangered-text {
  color: #dc2613;
}

.extinct-text {
  color: black;
}

.current-status {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  max-width: 170px;
  width: calc(100%/8);
  p{
    font-weight: 600;
  }
  span {
    font-size: 1.5rem;
  }

  @include for-tablet-landscape-down {
    width: calc(100%/4);
  }

  @include for-small-phone-down {
    height: 20vw;
    margin-bottom: 1vw;
    padding-top: 3.5vw;
    
    &.justify-end{
      justify-content: center !important;
    }

    .conservation-status-chart-boxes{
      span{
        margin: 1vw;
      }
    }
  }
}

.conservation-status-chart-id {
  max-height: 3vw;
  font-size: 0.8rem;
  @include for-big-desktop-down {
    font-size: 0.7rem;
  }
  @include for-desktop-down {
    font-size: 0.6rem;
  }
  @include for-tablet-landscape-down {
    font-size: 8px;
    margin-bottom: 0;
  }
}

.first-box-border {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.last-box-border {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.trees-profile-section-2,
.location-profile-section-6 {
  .big-info-chart-heading {
    @include for-desktop-down {
      margin-bottom: 0 !important;
    }
  }

  @include for-small-phone-down {
    min-height: unset !important;
  }
}

.user-order-section-2-wrap{
  .order-details{
    @include for-small-phone-down {
      h2{
        font-size: 1.3rem;
      }

      p{
        font-size: 0.9rem;
      }
    }
  }
}