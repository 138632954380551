.user-session-box {
  font-family: $spock;
  width: 540px;
  @include for-tablet-portrait-down {
    width: 80%;
  }
}

.user-session-input {
  height: 60px;
  border: none;
  font-size: 1.2rem;
  font-weight: 500;
  padding-left: 45px;
  font-style: italic;
  &::placeholder {
    color: $darkgreen;
  }

  @include for-tablet-landscape-down {
    height: 40px;
    font-size: 0.8rem;
  }
}

.user-session-input-icon {
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  margin-left: 10px;
}

//Checkbox

#remember-me {
  visibility: hidden;
}

.fake-checkbox {
  left: 0;
  top: 0;
  border: 3px solid $forest;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

#remember-me:checked ~ .fake-checkbox:after {
  z-index: 1;
  top: 3px;
  left: 1px;
  position: absolute;
  content: "";
  height: 7px;
  width: 13px;
  border-left: 4px solid $forest;
  border-bottom: 4px solid $forest;
  transform: rotate(-60deg) skew(-12deg);
}

.user-session-box .social-icons > a {
  @include for-tablet-portrait-up {
    height: 25px;
    width: 25px;
  }
}

.text-login-w-social-media {
  font-weight: 700;
}

.text-not-a-member {
  font-size: 1.5rem;
  > a {
    font-weight: 800;
    &:hover {
      text-decoration: underline;
    }
  }

  @include for-tablet-landscape-down {
    font-size: 1.2rem;
  }

  @include for-tablet-portrait-down {
    font-size: 1.1rem;
  }
}

.forgot-passwod{
  text-align: end;
  flex: 0 0 40%;
}