.recently-planted-card {
  width: 70vw;
  max-width: 900px;
  height: 330px;

  @include for-desktop-down {
    width: 350px;
    height: 500px;
  }

   @include for-tablet-portrait-down {
    width: 65vw;
    height: 60vh;
    padding: 0.5rem 0;
  }

  @include for-tablet-landscape-down{
    >div {
      >.align-center{
        align-items: unset;
      }
    }

    &.recently-planted-card-small{
      >div {
        >.align-center{
          align-items: center;
        }
      }
    }
  }
}

.recently-planted-card-height {
  height: 330px;

  @include for-desktop-down {
     height: 540px;
  }
}

.recently-planted-card-heading {
  text-transform: uppercase;
  font-weight: 800;
  font-family: $spock;
  font-size: 2.8rem;
  padding-top:5px;
  @include for-big-desktop-down {
    font-size: 2.4rem;
  }
  @include for-desktop-down {
    font-size: 2rem;
  }
  @include for-tablet-landscape-down {
    font-size: 1.8rem;
  }
  @include for-tablet-portrait-down {
    font-size: 1.3rem;
  }
}

.recently-planted-card-subheading {
  font-weight: 500;
  font-family: $spock;
  font-size: 1.7rem;
  letter-spacing: 0.05rem;

  @include for-big-desktop-down {
    font-size: 1.5rem;
  }
  @include for-desktop-down {
    font-size: 1.3rem;
  }
  @include for-tablet-landscape-down {
    font-size: 1.1rem;
  }
}

.recently-planted-card-information {
  font-weight: 500;
  font-family: $noto;
  font-style: italic;
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  margin-left: 40px;

  @include for-big-desktop-down {
    font-size: 1.2rem;
  }
  @include for-desktop-down {
    font-size: 1rem;
    margin-left: 0px;
    text-align: center;
  }
  @include for-tablet-landscape-down {
    font-size: 0.9rem;
  }

}


.recently-planted-card-image {
  height: 280px;
  width: 280px;
  object-fit: cover;
  border-radius: 50%;
  @include for-big-desktop-down {
    height: 250px;
    width: 250px;
  }

  @include for-tablet-landscape-down {
    height: 220px;
    width: 220px;
  }

  @include for-tablet-portrait-down {
    height: 55vw;
    width: 55vw;
  }

}

.recently-planted-card-small {
  &.recently-planted-card {
    width: 100%;
    height: 280px;

    @include for-big-desktop-down {
      height: 240px;
    }
    @include for-desktop-down {
      height: 180px;
    }
    @include for-tablet-landscape-down {
      height: 200px;
    }
    @include for-tablet-portrait-down {
      height: 180px;
    }
  }

  .recently-planted-card-heading {
    font-size: 2.2rem;
    
    @include for-extra-extra-large-down {
      font-size: 1.8rem;
    }
    @include for-big-desktop-down {
      font-size: 1.5rem;
    }
    @include for-desktop-down {
      font-size: 1.2rem;
    }
    @include for-tablet-landscape-down {
      font-size: 1.4rem;
    }
    @include for-tablet-portrait-down {
      font-size: 1rem;
    }
  }

  .recently-planted-card-subheading {
    font-size: 1.2rem;

    @include for-big-desktop-down {
      font-size: 1rem;
    }
    @include for-desktop-down {
      font-size: 0.7rem;
    }
    @include for-tablet-landscape-down {
      font-size: 1rem;
    }
    @include for-tablet-portrait-down {
      font-size: 1rem;
    }
  }

  .recently-planted-card-information {
    font-size: 1rem;
    margin-left: 30px;

    @include for-big-desktop-down {
      font-size: 0.8rem;
      margin-left: 0px;

      p{
        margin-left: 5% !important;
      }
    }

    @include for-desktop-down {
      font-size: 0.6rem;
    }

    @include for-tablet-landscape-down {
      font-size: 0.8rem;
      margin-left: 10px;
    }

    @include for-tablet-portrait-down {
      font-size: 0.6rem;
      margin-left: 0;
    }
  }

  .recently-planted-card-image {
    height: 220px;
    width: 220px;
    @include for-big-desktop-down {
      height: 170px;
      width: 170px;
    }

    @include for-desktop-down {
      height: 130px;
      width: 130px;
    }

    @include for-tablet-landscape-down {
      height: 160px;
      width: 160px;
    }

    @include for-tablet-portrait-down {
      height: 110px;
      width: 110px;
    }

  }

}
