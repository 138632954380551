.projects-section-5-column-padding {
  padding-bottom: 43px;
  @include for-tablet-landscape-down {
    padding-bottom: 0px;
  }
}

.sdgs-subtitle {
  font-family: $spock;
  font-size: 2rem;
  @include for-tablet-landscape-down {
    font-size: 1.3rem;
  }
}

.sdg-goals-size {
  height: 10vw;
  @include for-big-desktop-down {
    height: 11vw;
  }
  @include for-tablet-landscape-down {
    height: 16vw;
  }
}

.sdg-goals-popup-container {
  top: 0;
  left: 0;
  width: 100%;
  font-family: $spock;
  font-size: 0.8rem;
  font-weight: 600;

  @include for-big-desktop-down {
    width: 11vw;
  }
  @include for-tablet-landscape-down {
    width: 16vw;
  }
}

.sdg-goals-inner-container {
  // width: 130px;
  bottom: -10px;
  width: 10vw;
  @include for-big-desktop-down {
    width: 11vw;
  }
  @include for-tablet-landscape-down {
    width: 16vw;
  }
}

.sdg-goals-popup {
  // width: 280px;
  width: 22vw;
  @include for-big-desktop-down {
    width: 24vw;
  }
  @include for-tablet-landscape-down {
    width: 80vw;
  }
}

.sdg-goals-popup-tail {
  transform: translateY(-5px);
  width: 50px;
  height: 20px;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  @include for-big-desktop-down {
    width: 40px;
  }
  @include for-tablet-landscape-down {
    width: 30px;
  }
}
