.team-avatar {
  text-transform: uppercase;
  img {
    height: 400px;
    @include for-tablet-landscape-down {
      height: 300px;
    }
  }

  h2 {
    font-family: $spock;
    font-size: 2.8rem;
    font-weight: 900;

    @include for-big-desktop-down{
      font-size: 2.2rem;
    }

    @include for-tablet-landscape-down {
      font-size: 2rem;
      max-width: 260px;
    }
  }
  p {
    width:100%;
    font-weight: 900;
    font-family: $noto;
    font-style: italic;
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    @include for-tablet-landscape-down {
      font-size: 0.9rem;
    }
  }
}
