.responsive-header-padding {
  padding: 4rem 6rem 3rem 6rem;

  @include for-desktop-down {
    padding: 3rem 5rem 3rem 5rem;
  }

  @include for-tablet-landscape-down {
    padding: 3rem 4rem 1rem 4rem;
  }

  @include for-tablet-portrait-down {
    padding: 3rem 2rem 1rem 2rem;
  }
}

.user-tree-id-tab-wrap{
  @include for-small-phone-down {
    padding: 1rem;

    h2.section-heading{
      text-align: left !important;
      margin-left: 8vw;
    }
  }
}
