
.col-2-height {
  height: auto;

  @include for-tablet-portrait-down {
    height: 100%;
  }
}

.footer-logo {
  height: 80px;
  width: 168px;

  @include for-tablet-landscape-down {
    height: 60px;
    width: 126px;
  }
}

.nav-links-footer {
  width: 55%;
  a {
    color: $darkgreen;
  }

  @include for-tablet-landscape-down {
    font-size: 0.8rem;
  }

  @include for-tablet-portrait-down {
    width: 100%;
  }
}

.nav-links-border {
  border-left: 2px solid $darkgray;
  border-right: 2px solid $darkgray;

  @include for-tablet-portrait-down {
    border-left: none;
    border-right: none;
    border-top: 2px solid $darkgray;
    border-bottom: 2px solid $darkgray;
  }
}

.subscribe-border {
  border-left: 2px solid $darkgray;

  @include for-tablet-portrait-down {
    border-left: none;
    border-top: 2px solid $darkgray;
  }
}

.li-spacing {
  li {
    margin-bottom: 1rem;
  }
  li:last-child {
    margin-bottom: 0rem;
  }
}

.skew-transition {
  li {
    transition: 0.2s;
  }
  li:hover {
    transform: skew(-10deg);
  }
}

.unskew-transition {
  li {
    transform: skew(-10deg);
    transition: 0.2s;
  }

  li:hover {
    transform: skew(0);
  }
}

.footer-heading {
  font-size: 1.1rem;
}

.info-width {
  width: 45%;

  @include for-tablet-landscape-down {
    font-size: 0.8rem;
  }

  @include for-tablet-portrait-down {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.follow-us-width {
  width: 35%;
  @include for-tablet-portrait-down {
    width: 100%;
    margin-bottom: 2rem;
    padding-top: 1rem;
  }
}

.subscribe-width {
  width: 65%;
  @include for-tablet-portrait-down {
    width: 100%;
    padding-top: 1rem;
    p {
      font-size: 0.8rem;
    }
  }
}


.footer-icons {
  span {
    height: 27px;
    width: 27px;
    background-color: $darkgreen;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.4rem;
    transition: 0.2s;
  }

  a {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  svg {
    height: 16px;
    width: 16px;
    color: white;

  }

  p {
    font-style: italic;
    color: $darkgreen;
    font-family: $spock;
    margin: 0;
    transition: 0.2s;
  }

  a:hover {
    span {
      transform: scale(1.1);
    }
    p {
      transform: translateX(3px);
    }
  }

  @include for-tablet-landscape-down {
    font-size: 0.8rem;
  }
}


.footer-subscribe-form {
  position: relative;
  min-height: 60px;
  height: 60px;

  @include for-small-phone-down {
    min-height: 0;
    height: 40px;
  }

  input {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    padding-left: 1rem;
    padding-right: calc(60px + 1rem);
    font-size: 1rem;
    font-style: italic;
    font-family: $spock;
    color: white;

    &.bg-white {
      color:$darkgreen;
    }

    &::placeholder {
      color: $darkgray;
    }
    &::-ms-input-placeholder {
      color: $darkgray;
    }
    &:-ms-input-placeholder {
      color: $darkgray;
    }

    @include for-tablet-landscape-down {
      font-size: 1rem;
    }
  }

}

.footer-subscribe-button {
  position: absolute;
  height: 100%;
  right: -1px;
  top: 0;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 30px;
    width: 36px;
    transform: translateX(3px);

    @include for-small-phone-down {
      height: 24px;
      width: 28px;
    }
  }
}

.footer-subscribe-button-clicked {
  width: 100%;
}

.copyright-strip {

}
