.quoted-text {
  position: absolute;
  z-index: 1;
  top: -20px;
  left: -50px;
  background-color: $lightgray;
  padding: 20px 10px;
  border-radius: 8px;
  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -40px;
    height: 60px;
  }
  p {
    font-size: 0.8rem;
    color: $darkgray;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.05rem;

    @include for-tablet-portrait-down {
      font-size: 0.7rem;
    }
  }
  ~ p {
    font-style: normal;
    font-size: 1rem;

    @include for-tablet-portrait-down {
      font-size: 0.75rem;
    }
  }
}

.climate-change {
  position: absolute;
  z-index: 1;
  bottom: -20px;
  right: -10px;
  @include for-tablet-portrait-down {
    right: -40px;
  }
}


.nested-text {
  font-size: 1.2rem;
  p {
    font-style: italic;
  }
  @include for-big-desktop-down {
    font-size: 1rem;
  }
  @include for-desktop-down {
    font-size: 0.9rem;
  }
  @include for-tablet-landscape-down {
    font-size: 0.6rem;
  }
}
