@import "./styles/index";
@import 'react-toastify/dist/ReactToastify.css';

.App {
  text-align: center;
}


button {
  @include not-for-ios {
    cursor: pointer;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
}


.side-panel-open {
  @include for-tablet-portrait-down {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

.pop-up-open {
  overflow: hidden;
  
}

.iframe-wrap {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  border-radius: 3px;
  width:fit-content !important;

  iframe {
    width:600px;
    height:600px;
    margin-top:5rem;
  
    @include for-small-phone-down {
      width:350px;
      height:450px;
    }
  }
}

