.pop-up-container {
  height: 100vh;
  width: 100vw;
  min-height: 500px;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;

  @include for-desktop-down {
    .controlled-carousel-container {
      width: 100% !important;
    }
    
    .react-multi-carousel-item{
      .column-2{
        column-count: unset;
      }
    }
  }

}

.add-to-cart-popup {
  background-color: $lightgreen;
  max-width: 320px;
  min-height: 400px;
  max-height: 520px;
  width: 90%;
  height: 500px;
  padding: 20px 30px;
}

.add-to-cart-headings {
  font-family: $spock;
  margin: 0 0 10px 0;

  h2 {
    margin: 0;
    font-size: 1.5rem;
    letter-spacing: -0.1rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  h3 {
    margin: 0;
    font-size: 1.1rem;
    font-style: italic;
    color: $darkgreen;
    font-weight: 500;
  }
  h4 {
    margin: 0;
    font-size: 1.6rem;
    color: white;
    font-weight: 700;
  }

}

.add-to-cart-image {
  height: 200px;
}

.customize-you-purchase {
  font-family: $spock;

  h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1rem;
    text-align: center;
    letter-spacing: 0.1rem;
    font-weight: 800;
    text-transform: uppercase;
  }

  label {
    text-align: center;
    font-size: 0.7rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    font-style: italic;
    margin-bottom: 10px;
  }

  input[type="submit"] {
    border: 0;
    margin-left: auto;
    margin-right: 5%;
    font-weight: 600;
    letter-spacing: 0.1rem;
  }
}

.stylized-text-input {
  padding-left: 10px;
  font-family: $spock;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.5rem;
  font-style: italic;
  height: 30px;
  border: 0;
  outline: 0;
  margin-bottom: 20px;

  @include for-big-desktop-down {
    font-size: 0.7rem;
  }

  @include for-desktop-down {
    font-size: 0.5rem;
  }

  @include for-tablet-landscape-down {
    height: 18px;
    font-size: 0.3rem;
    margin-bottom: 10px;
  }
}

.native-store-section-2 {
  .dynamic-height-pop-up {
    height: auto;
  }
}

.dynamic-height-pop-up {
  height: 490px;
  @include for-desktop-down {
    height: 415px;
  }
}
