// Currently unused, but this works - replace the npm package with this

.card-order{
  .card-spacer{
    display: none;
  }

  .card-top{
    p{
      margin: 0 !important;
      color: #fff;
    }
  }

  .card-bottom{
    .card-text, .card-price{
      margin-top: 1em !important;
    }
  }
}
