.cookie-policy {
  position: fixed;
  bottom: 20px;
  left: 40px;
  z-index: 10;
  padding:0 3rem;

  max-width: 450px;

  background-color: white;
  border-radius: 7px;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
  text-align: center;

  font-family: $spock;
  text-transform: uppercase;

  @include for-tablet-portrait-down {
    width: 300px;
    transform: translateX(-50%)
  }

  & h3 {
    font-size: 28px;
    font-weight: 800;
    margin: 12px 0 4px 0;

    @include for-tablet-portrait-down {
      margin: 8px 0 2px 0;
      font-size: 18px;
    }
  }
  & p {
    text-transform: none;
    font-family: $noto;
    font-size: 13px;
    color: $darkgreen;
    font-weight: 800;
    font-style: italic;
    line-height: 1.1;
    margin: 0;

    @include for-tablet-portrait-down {
      font-size: 10px;
    }
  }

  & a {
    font-family: $noto;
    text-decoration: none;
    font-size: 13px;
    font-weight: 800;
    margin-top: 10px;
    margin-left: auto;

    @include for-tablet-portrait-down {
      font-size: 10px;
      margin-top: 5px;
    }
  }

  & .general-button {
    font-family: $spock;
    padding: 8px 10px;
    letter-spacing: 1px;
    margin: auto;

    @include for-tablet-portrait-down {
      padding: 6px 8px;
    }
  }
}

.cookie-leaves {
  height: 180px;
  width: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 5px;

  -webkit-filter: drop-shadow(12px 12px 25px rgba(0,0,0,0.5));
      -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
      filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";

  @include for-tablet-portrait-down {
    height: 120px;
    width: 50px;
  }
}

.cookie-leaves-1 {
  left: -30px;
  @include for-tablet-portrait-down {
    left: -20px;
  }
}

.cookie-leaves-2 {
  right: -45px;
  @include for-tablet-portrait-down {
    right: -30px;
  }
}

.float-right {
  float: right;
}

.close-cookies {
  font-weight: 800;
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 2%;
  right: 1%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  @include for-big-desktop-down {
    align-items: unset;
    line-height: 1rem;
  }

  @include for-small-phone-down {
    top: 5px;
  }
}
