.small-icon {
  height: 16px;
  @include for-tablet-landscape-down {
    height: 12px;
  }

  @include for-tablet-portrait-down {
    height: 9px;
  }
}

// .dynamic-padding {
//   padding-left: 40px;
//   @include for-tablet-portrait-down {
//     padding: 0;
//   }
// }

.info-popup-height {
  max-height: 680px;

  @include for-extra-extra-large-down  {
    max-height: unset;
  }

  @include for-big-desktop-down  {
    max-height: unset;
  }

  @include for-tablet-landscape-down {
    max-height: 97%;
  }
}

.info-popup-width {
  width: 80%;
  @include for-tablet-landscape-down {
    width: 95%; 
  }
}

.tree-of-month,
.tree-profile-wrap{
  .info-popup-width {
    width: auto;
  }
}

