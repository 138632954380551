.card {
  position: relative;
  height: 350px;
  width: 240px;
  font-family: $spock;

  &.has-tree{
    height:450px;
    form {
        // margin-top: auto;
        margin-top: unset;
    }
    .card-spacer {
      height: 43%;
    }

    .card-bottom {
      height:81%;
    }
  }
}

//for carousel
.card-height {
  overflow-y: visible;
  height: 330px;
}

.small-card-height {
  height: 290px;
  @include for-tablet-landscape-down {
    height: 186px;
  }
}

.card-top {
  height: 15%;
  width: 100%;
  p {
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: .2rem;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    margin-left:0.5rem;
  }
}

.card-bottom {
  height: 85%;
  width: 100%;
  line-height: 1.2;
}

.card-image {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  height: 67%;
  width: 100%;
}

.landing-page-section-3{
  .card-image{
    height: 42%;
    top:40px;
  }

  .card.has-tree .card-bottom {
    height:80%;

    @include for-small-phone-down{
      // height:83%;
    }
  }


}

.card-image-shortened {
  top: 40px;
  height: 180px;
}

.card-button {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;

  @include for-tablet-landscape-down{
    bottom: -16px;
  }
}

.card-spacer {
  height: 53%;
  width: 100%;
}

.card-heading {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1;

  @include for-extra-extra-large-down {
    font-size: 1.6rem;
    margin-top: 13%;
  }

  @include for-extra-extra-large-up{
    font-size: 1.6rem;
    margin-top: 13%;
  }
}

.card-text {
  font-weight: 400;
  font-size: 1rem;
}

.card-price {
  font-style: italic;
  font-weight: 500;
  font-size: 1.5rem;
}

.card-small {
  height:265px;
  width: 200px;

  .card-heading {
    padding-top: 15px;
    font-weight: 900;
    font-size: 1.1rem;
  }

  .card-price {
    font-style: italic;
    font-weight: 700;
    font-size: 1.2rem;
    margin-top: 4% !important;
  }

  .quantity-label {
    font-size:0.9rem;
  }

  .quantity-select {
    input[type=number]{
      font-size:0.9rem;
    }
  }

  &.has-tree {
    height: 330px;

    .card-spacer {
      // height: 44%;
      height: 39%;
    }

    .card-image {
      height: 55%;
    }

    .card-bottom {
      height:83%;
    }

    .value-increment {
      width:20px;
      height:20px;

      div {
        height:10px;
        width:10px;
        position:relative;
        right:1px;
      }
    }
  }

  @include for-desktop-down {
    transform: scale(0.85);
  }

  @include for-tablet-landscape-down {
    transform: scale(0.8);
  }
}

.flip-card-back {
  .card-small.has-tree {
    padding-bottom:2rem;

    .quantity-label,.quantity-select input[type=number]  {
      color:white;
    }
  }
}

@include for-tablet-landscape-down {
  .card-dynamic {
    transform: scale(0.8);
  }
}

.card-heading-back {
  margin-bottom: 5px;
  h2 {
    margin: 0;
    line-height: 1;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  h3 {
    margin: 0;
    font-size: 0.6rem;
    font-style: italic;
    font-weight: 500;
    color: $white;
    width: 118px;
    text-overflow: ellipsis;
    /* Needed to make it work */
    overflow: hidden;
    white-space: nowrap;
  }
  h4 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 700;
  }
  a {
    color: white;
    font-size: 0.6rem;
    line-height: 1;
    margin:0.2rem 0;
    text-align: center;
    p {
      margin-top: 5px;
      margin-bottom: 5px;
      font-weight: 800;
    }
  }
}

.related-tree-icon {
  height: 40px;
  width: 40px;
}

.animal-reverse-heading {
  font-size: 0.9rem;
  font-style: italic;
  text-transform: uppercase;
}

///////////////////

