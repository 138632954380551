.native-store-card {
  font-family: $noto;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  @include for-tablet-portrait-down {
    width: 80%;
  }
}

.native-store-card-heading {
  font-size: 1.8rem;
  font-weight: 500;
  @include for-tablet-landscape-down {
    font-size: 1.6rem;
  }
  @include for-tablet-portrait-down {
    font-size: 1.2rem;
  }
}

.native-store-card-image {
  height: 460px;
  max-width: 360px;
  @include for-tablet-portrait-down {
    height: 400px;
    max-width: 100%;
    width: 100%;
  }
}

.native-store-card-text {
  h3 {
    font-family: $spock;
    font-size: 1.8rem;
    line-height: 1;
    @include for-tablet-landscape-down {
      font-size: 1.6rem;
    }
    @include for-tablet-portrait-down {
      font-size: 1.2rem;
    }
  }
  p {
    font-size: 0.7rem;
    @include for-tablet-landscape-down {
      font-size: 0.6rem;
    }
    @include for-tablet-portrait-down {
      font-size: 0.5rem;
    }
  }
}
