$height: 18px;
$width: 70px;

.header-bar {
  position: fixed;
  top: 0;
  pointer-events: none;
  z-index: 100;
  display: flex;
  justify-content: space-between;

  a {
    height: fit-content;
  }
}

.header-logo-container {
  // margin: 10px;
  position: relative;
  height: 90px;
  // width: 90px;
}

.header-logo {
  pointer-events: auto;
  height: 90px;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.3s;
  width: 186px;
  
  @include for-desktop-down {
    height: 70px;
    width: 145px;
  }
  @include for-tablet-landscape-down {
    height: 60px;
    width: 124px;
  }
  @include for-tablet-portrait-down {
    height: 40px;
    width: 82px;
  }
}

.white-logo {
  z-index: 2;
}

.green-logo {
  z-index: 1;
  position: absolute;
}

.transparent {
  opacity: 0;
}

.es-text {
    letter-spacing: 1px !important;
}

.header-button {
  pointer-events: auto;
  border-radius: 8px;
  background: white;
  border: 0;
  height: 50px;
  width: 50px;
  padding: 0;
  margin-bottom: 8px;
  box-shadow: 2px 3px 4px rgba(0,0,0,0.2);

  @include for-tablet-portrait-down {
    height: 42px;
    width: 42px;
  }

  &>p{
    margin: 0;
    font-size: 8px;
    letter-spacing: 4px;
    font-family: $spock;
    font-weight: 800;
    padding-left: 4px;

    @include for-tablet-landscape-down {
      font-size: 7px;
    }

    @include for-tablet-portrait-down {
      font-size: 6px;
      letter-spacing: 3px;
    }
  }
  &:focus{
    box-shadow: 0;
    border: 0;
    outline: none;
  }
}

.header-button-store {
  width: 25px !important;

  @include for-tablet-landscape-down {
    width: 20px !important;
  }

  @include for-tablet-portrait-down {
    width: 16px !important;
  }
}

.header-button-cart {
  width: 35px !important;

  @include for-tablet-landscape-down {
    width: 28px !important;
  }

  @include for-tablet-portrait-down {
    width: 22px !important;
  }

}

.header-button-faqs {
  width: 43px !important;

  @include for-tablet-landscape-down {
    width: 35px !important;
  }

  @include for-tablet-portrait-down {
    width: 28px !important;
  }
}

.header-button-icon {
  pointer-events: auto;
  height: 34px;
  width: 25px;

  @include for-tablet-landscape-down {
    height: 28px;
    width: 20px;
  }

  @include for-tablet-portrait-down {
    height: 22px;
    width: 16px;
  }
}


.side-menu-toggle {
  pointer-events: auto;
  z-index: 6;
  height: $height;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  border: 0;
  padding: 0;
  margin-bottom: 10px;

  @include for-tablet-portrait-down {
    width: 44px;
  }
}

.menu-toggle-line {
  height: 7px;
  width: 50px;
  border-radius: 10px;
  margin-left: auto;
  transition: width 0.2s 0.2s, background-color 0.3s;
  box-shadow: 2px 3px 4px rgba(0,0,0,0.2);

  @include for-tablet-portrait-down {
    height: 6px;
    width: 42px;
  }
}

.half-line {
  width: 25px;
  @include for-tablet-portrait-down {
    width: 21px;
  }
}


.cart-count {
  background-color: #e10c0c;
  border-radius: 50%;
  color: white;
  font-family: $spock;
  position: absolute;
  height: 18px;
  width: 18px;
  max-height: 18px;
  max-width: 18px;
  min-height: 18px;
  min-width: 18px;
  top: 4px;
  right: 4px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;

  @include for-tablet-landscape-down {
    top: 2px;
    right: 2px;
    font-size: 0.6rem;
  }

  @include for-tablet-portrait-down {
    top: -4px;
    right: -4px;
    font-size: 0.5rem;
  }

}
