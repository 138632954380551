ul[role="navigation"]{
    display: inline-flex;
    list-style-type: none;
    flex-wrap: wrap;
  
    a {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
    }
  
    li{
        :hover{
            cursor: pointer;
        }
  
        &.selected {
            background-color: #348a41;
            border-radius: 8px;
  
            a{
                color: white;
            }
        }
    }
  }
  