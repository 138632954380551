

/* Fonts */

$thin: 100;
$ultralight: 200;
$light: 300;
$normal: 400;
$bold: 700;
$black: 800;

@font-face {
  font-display: block;
  font-family: "NotoSerif";
  font-weight: $normal;
  font-style: normal;
  src: url("fonts/Noto_Serif/NotoSerif-Regular.ttf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "NotoSerif";
  font-weight: $bold;
  font-style: normal;
  src: url("fonts/Noto_Serif/NotoSerif-Bold.ttf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "NotoSerif";
  font-weight: $normal;
  font-style: italic;
  src: url("fonts/Noto_Serif/NotoSerif-Italic.ttf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "NotoSerif";
  font-weight: $bold;
  font-style: italic;
  src: url("fonts/Noto_Serif/NotoSerif-BoldItalic.ttf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $thin;
  font-style: normal;
  src: url("fonts/Spock_Pro/SpockPro-Thin.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $thin;
  font-style: italic;
  src: url("fonts/Spock_Pro/SpockPro-ThinItalic.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $ultralight;
  font-style: normal;
  src: url("fonts/Spock_Pro/SpockPro-UltraLight.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $ultralight;
  font-style: italic;
  src: url("fonts/Spock_Pro/SpockPro-UltraLightItalic.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $light;
  font-style: normal;
  src: url("fonts/Spock_Pro/SpockPro-Light.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $light;
  font-style: italic;
  src: url("fonts/Spock_Pro/SpockPro-LightItalic.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $normal;
  font-style: normal;
  src: url("fonts/Spock_Pro/SpockPro-Regular.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $normal;
  font-style: italic;
  src: url("fonts/Spock_Pro/SpockPro-RegularItalic.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $bold;
  font-style: normal;
  src: url("fonts/Spock_Pro/SpockPro-Bold.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $bold;
  font-style: italic;
  src: url("fonts/Spock_Pro/SpockPro-BoldItalic.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $black;
  font-style: normal;
  src: url("fonts/Spock_Pro/SpockPro-Black.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

@font-face {
  font-display: block;
  font-family: "SpockPro";
  font-weight: $black;
  font-style: italic;
  src: url("fonts/Spock_Pro/SpockPro-BlackItalic.otf");
  //for cross-compatiblity, having all font-types to fallback on would ensure all browsers display fonts - need .eot, .woff, .svg
}

$noto: 'NotoSerif';
$spock: 'SpockPro';

//////////////////////////
/*Colors*/
$forest: #348a41;
$lightgreen: #8cc871;
$darkgreen:  #294424;
$lightgray: #EAE7E8;
$darkgray: #7b7979;
$red: #F02D3A;
$darkred: #963635;
//////////////////////////


/*Width Breakpoints*/
@mixin for-small-phone-down {
  @media (max-width: 575px) { @content; }
}

@mixin for-tablet-portrait-down {
  @media (max-width: 575px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 576px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 768px) { @content; }
}

@mixin for-tablet-landscape-down {
  @media (max-width: 767px) { @content; }
}

@mixin for-desktop-down {
  @media (max-width: 991px) { @content; }
}

// lg - (bootstrap)
@mixin for-desktop-up {
  @media (min-width: 992px) { @content; }
}

// xl - (bootstrap)
@mixin for-big-desktop-up {
  @media (min-width: 1200px) { @content; }
}

// xxl (bootstrap)
@mixin for-extra-extra-large-up {
  @media (min-width: 1399px) { @content; }
}

// lg - xl (bootstrap)
@mixin for-big-desktop-down {
  @media (max-width: 1199px) { @content; }
}

// xl - xxl (bootstrap)
@mixin for-extra-extra-large-down {
  @media (max-width: 1399px) { @content; }
}

@mixin for-bigger-than-preset-page-height {
  @media (min-height: 721px) { @content; }
}


// /*Height Breakpoints*/
// @mixin for-phone-only {
//   @media (max-width: 599px) { @content; }
// }
// @mixin for-tablet-portrait-up {
//   @media (min-width: 600px) { @content; }
// }
// @mixin for-tablet-portrait-down {
//   @media (max-width: 899px) { @content; }
// }
// @mixin for-tablet-landscape-up {
//   @media (min-width: 900px) { @content; }
// }
// @mixin for-tablet-landscape-down {
//   @media (max-width: 1199px) { @content; }
// }
// @mixin for-desktop-up {
//   @media (min-width: 1200px) { @content; }
// }
// @mixin for-desktop-down {
//   @media (max-width: 1799px) { @content; }
// }
// @mixin for-big-desktop-up {
//   @media (min-width: 1800px) { @content; }
// }

//////////////////////////


/*iPhone*/
@mixin for-ios {
  @supports (-webkit-touch-callout: none) {@content}
}

@mixin not-for-ios {
  @supports not (-webkit-touch-callout: none) {@content}
}
//////////////////////////
