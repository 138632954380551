.section-padding {
  padding-left: 40px;
  // padding-right: 40px;
  @include for-tablet-landscape-down {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.settings-profile-photo {
  pointer-events: auto;
  height: 170px;
  width: 170px;
  min-height: 170px;
  min-width: 170px;
  @include for-desktop-down {
    height: 150px;
    width: 150px;
    min-height: 150px;
    min-width: 150px;
  }
  @include for-tablet-landscape-down {
    height: 120px;
    width: 120px;
    min-height: 120px;
    min-width: 120px;
  }

  @include for-tablet-portrait-down {
    height: 100px;
    width: 100px;
    min-height: 100px;
    min-width: 100px;
  }
}

.settings-label-size {
  font-size: 1.1rem;
}

.settings-input-size {
  font-size: 1rem;
}

.language-input-width {
  width: 50%;
}

.save-button-margin {
  @include for-desktop-down {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  @include for-small-phone-down {
    margin-top: 40px;
  }
}


.settings-input-underline {
  margin-top: 0.2rem;
  width: 0px;
  border-bottom: 1px solid $darkgreen;
  transition: 0.1s;
}

.secure-icon {
  height: 24px;
  width: 24px;
}
